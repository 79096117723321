import React, { createContext, useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../Context/WorkflowContext";
// import { candidateFormWorkflow } from './StepperFormJson'

export const SequenceContext = createContext(null);

function SequenceContextProvider({ children }) {
  const { candidateFormWorkflow } = useContext(WorkflowContext);

  const [level, setLevel] = useState(0);
  const [sequenceList, setSequenceList] = useState([]);
  const [currentSequence, setCurrentSequence] = useState([]);
  console.log("currentSequence: ", currentSequence)
  const [submitLoading, setSubmitLoading] = useState(false);

  const [steppers, setSteppers] = useState([]);
  console.log("steppers: ", steppers)
  const [currentForm, setCurrentForm] = useState(null);
  console.log("currentForm: ", currentForm)


  const getCurrentFormName = (seq, tabs) => {
    console.log("seq: ", seq)
    console.log("tab: ", tabs)
    if (seq.length === 1) {
      return tabs[seq[0]];
    }
    const nextTabs = tabs[seq[0]]?.tabs;
    seq.shift();
    return getCurrentFormName(seq, nextTabs);
  };

  // Handle Next button
  // Goto next tab only if next tab exists
  const nextLevel = () => {
    if (sequenceList.length - 1 > level) {
      setLevel((level) => (level += 1));
    }
  };

  // Handle Prev button
  // Goto previous tab only if previous tab exists
  const prevLevel = () => {
    if (level > 0) {
      setLevel((level) => (level -= 1));
    }
  };

  useEffect(() => {
    if (sequenceList.length !== 0) {
      setCurrentSequence(sequenceList[level]);
    }
  }, [level, sequenceList]);

  useEffect(() => {
    if (currentSequence.length !== 0 && steppers.length !== 0) {
      setCurrentForm(
        getCurrentFormName([...currentSequence], [...steppers.tabs])
      );
    }
  }, [currentSequence, steppers]);

  // Set sequence state in current state of Sequence Context
  useEffect(() => {
    if (
      candidateFormWorkflow !== null &&
      candidateFormWorkflow.sequence &&
      candidateFormWorkflow.sequence.length !== 0
    ) {
      setSteppers(candidateFormWorkflow);
      setSequenceList(candidateFormWorkflow?.sequence);
    }
  }, [candidateFormWorkflow]);

  const value = {
    level,
    setLevel,
    sequenceList,
    setSequenceList,
    currentSequence,
    nextLevel,
    prevLevel,
    currentForm,
    submitLoading,
    setSubmitLoading,
  };

  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  );
}

export default SequenceContextProvider;
