import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import Swal from "sweetalert2";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const EditPosition = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const { getDepartmentData } = useContext(MasterTableContext);

  const {
    isGenricDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    getGenericDropdownData,
  } = useContext(GenericDropdownContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const [filteredTeamData, setFilteredTeamData] = useState([]);
  console.log(filteredTeamData);
  const [departmentEntry, setDepartmentEntry] = useState(null);
  console.log(departmentEntry);

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    let user = new FormData();
    user.append("position_name", data.position_name);
    user.append("position_display_name", data.position_display_name);
    user.append("department", data.department.value);
    user.append("team_id", data.team.value);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + `/api/position/${location.state.editpost.id}/`;
    console.log(url);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Position updated successfully",
        });
        getGenericDropdownData();
        setTimeout(() => {
          navigate("/position");
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update position",
          });
        }
      });
  };

  const deletePosition = () => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url +
      `/api/position/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };
    axios(config)
      .then((response) => {
        getGenericDropdownData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the position",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setActiveStatus(0);
          RefreshToken();
        } else {
          setActiveStatus(1);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the position",
          });
        }
        console.log(error);
      });
  };

  const deletePositionAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePosition();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedPosition = location.state.editpost;
    if (editedPosition) {
      var defaultValues = {};
      defaultValues.position_name = editedPosition.position_name;
      defaultValues.position_display_name =
        editedPosition.position_display_name;
      const getDepartment = {
        value: editedPosition.department?.id,
        label: editedPosition.department?.department_display_name,
      };
      defaultValues.department = getDepartment;
      const getTeam = {
        value: editedPosition.team?.id,
        label: editedPosition.team?.team_display_name,
      };
      defaultValues.team = getTeam;
      reset(defaultValues);
    }
  }, []);

  const teamOptionCondition = () => {
    return departmentEntry
      ? "No Team Under the selected department"
      : "Please Select Department first";
  };

  useEffect(() => {
    console.log(departmentEntry?.label);
    const rawfilteredTeamData = teamDropdownData.filter(
      (info) => info?.department_display_name == departmentEntry?.label
    );
    setFilteredTeamData(rawfilteredTeamData);
  }, [departmentEntry]);

  useEffect(() => {
    getDepartmentData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Position </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          {privileges.includes("position_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleteposition"
                  checked={activeStatus}
                  onChange={(e) => {
                    deletePositionAlert(location.state?.editpost?.id);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-deleteposition"
                ></label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.department_display_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          onChange={(e) => {
                            setDepartmentEntry(e);
                            field.onChange(e);
                          }}
                          isClearable={true}
                          id="select-forms-department"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="department"
                      control={control}
                    />
                    {errors.department && (
                      <p className="fs-8 text-danger">Department is required</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Team <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredTeamData?.map((info) => ({
                            value: info.id,
                            label: info.team_display_name,
                          }))}
                          noOptionsMessage={teamOptionCondition}
                          isClearable={true}
                          id="select-forms-team"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="team"
                      control={control}
                    />
                    {errors.team && (
                      <p className="fs-8 text-danger">Team is required</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Position Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter position name"
                    id="text-forms-position_name"
                    {...register("position_name", { required: true })}
                  />
                  {errors.position_name && (
                    <span className="fs-8 text-danger">
                      Position name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Position Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter position display name"
                    id="text-forms-position_display_name"
                    {...register("position_display_name", { required: true })}
                  />
                  {errors.position_display_name && (
                    <span className="fs-8 text-danger">
                      Position display name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editposition"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditPosition;
