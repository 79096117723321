import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { JobAssignmentContext } from "../../Context/JobAssignment";
import Swal from "sweetalert2";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const JobAssignmentEdit = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [activeStatus, setActiveStatus] = useState(1);

  const { getJobAssignmentData } = useContext(JobAssignmentContext);
  const { getGenericDropdownData } = useContext(GenericDropdownContext);

  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const defaultAssignmentDocument = location.state.editpost.assignment_document;
  console.log("defaultAssignmentDocument:", defaultAssignmentDocument);
  const [assignmentDocument, setAssignmentDocument] = useState(null);
  console.log("assignmentDocument:", assignmentDocument);
  const [filePreview, setFilePreview] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    user.append("job_opening_id", location.state.editpost.job_opening.id);
    user.append("assignment_title", data.assignment_title);

    if (defaultAssignmentDocument !== null) {
      console.log("already");
      user.append("assignment_document", defaultAssignmentDocument);
    } else {
      console.log("new");
      user.append("assignment_document", data.assignment_document);
    }
    user.append("updated_by_id", userID);

    const url =
      VARIABLES.url + `/api/jobassignment/${location.state?.editpost?.id}/`;
    console.log(url);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getJobAssignmentData();
        getGenericDropdownData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully updated assignment",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update assignment",
          });
        }
      });
  };

  const deleteAssignment = () => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url +
      `/api/jobassignment/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };
    axios(config)
      .then((response) => {
        getJobAssignmentData();
        getGenericDropdownData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the assignment",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setActiveStatus(0);
          RefreshToken();
        } else {
          setActiveStatus(1);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the assignment",
          });
        }
        console.log(error);
      });
  };

  const deleteAssignmentAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAssignment();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedData = location.state.editpost;
    if (editedData) {
      var defaultValues = {};
      defaultValues.assignment_title = editedData.assignment_title;
      // defaultValues.assignment_document = editedData.assignment_document;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (location.state.editpost.assignment_document) {
      setAssignmentDocument(location.state.editpost.assignment_document);
      setValue(
        "assignment_document",
        location.state.editpost.assignment_document
      );
    }
  }, [location.state.editpost.assignment_document, setValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Edit Job Assignment</h3>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div
            className="card-inner d-flex pt-3 pb-0"
            style={{
              justifyContent: defaultAssignmentDocument
                ? "space-between"
                : "flex-end",
            }}
          >
            {defaultAssignmentDocument && (
              <div class="nk-block-des text-soft">
                <span>View Assignment : </span>
                <a
                  href={defaultAssignmentDocument}
                  target="_blank"
                  className="text-start"
                  id="assignment-link"
                >
                  Uploaded Assignment Link
                </a>
              </div>
            )}
            {privileges.includes("job_assignment_delete") ? (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleteassignment"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteAssignmentAlert(location.state?.editpost?.id);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-deleteassignment"
                ></label>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-assignment_title"
                      placeholder="Enter assignment title"
                      {...register("assignment_title", {
                        required: true,
                      })}
                    />
                    {errors.assignment_title && (
                      <span className="fs-8 text-danger">
                        Assignment title is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Document{" "}
                      <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="assignment_document"
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => value.type === "application/pdf",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-assignment_document"
                          type="file"
                          onChange={(e) => {
                            field.onChange(e.target.files[0]);
                            setAssignmentDocument(e.target.files[0]);
                            handleResumeChange(e.target.files);
                          }}
                        />
                      )}
                    />
                    {errors.assignment_document && errors.assignment_document.type === "required" && (
                      <span className="fs-8 text-danger">Assignment document is required</span>
                    )}
                    {errors.assignment_document && errors.assignment_document.type === "validate" && (
                      <span className="fs-8 text-danger">
                        Please upload your document in pdf format
                      </span>
                    )}
                    {filePreview && (
                      <a
                        className="btn btn-primary mt-2"
                        href={filePreview}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview Uploaded File
                      </a>
                    )}
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editjobassignment"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobAssignmentEdit;
