import React from 'react'

const Message = (props) => {
    console.log(props.icon)
    return (
        <>
            <div className="card">
                <div className="card-inner" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img
                        src={props?.icon}
                        alt="okay"
                        style={{
                            height: "10rem",
                            margin: "10px"
                        }}
                    />
                    <h4 style={{
                        margin: "10px",
                        color: "red"
                    }}>{props?.message}</h4>
                </div>
            </div>
        </>
    )
}

export default Message