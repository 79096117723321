import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const RequestedJobOpeningContext = createContext(null);

function RequestedJobOpeningProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  // requested requisition for the particular user to display.
  const [isRequisitionByUserData, setIsRequisitionByUserData] = useState(false);
  const [requisitionByUserData, setRequisitionByUserData] = useState([]);
  const [requisitionByUserEntries, setRequisitionByUserEntries] = useState(5);
  const [requisitionByUserCount, setRequisitionByUserCount] = useState(null);
  const [requisitionByUserPageCount, setRequisitionByUserPageCount] = useState(0);
  const [isRequisitionByUserLoading, setIsRequisitionByUserLoading] = useState(true);

  // requisition to display.
  const [isData, setIsData] = useState(false);
  const [rawRequestedJob, setRawRequestedJob] = useState([]);
  const [requestedEntries, setRequestedEntries] = useState(5);
  const [requestedCount, setRequestedCount] = useState(null);
  const [requestPageCount, setRequestedPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // all requisition data to display user
  const [isRequisitionData, setIsRequisitionData] = useState(false);
  const [rawRequisitionData, setRawRequisitionData] = useState([]);
  console.log(rawRequisitionData);
  const [requisitionEntries, setRequisitionEntries] = useState(5);
  const [requisitionCount, setRequisitionCount] = useState(null);
  const [requisitionPageCount, setRequisitionPageCount] = useState(0);
  const [isRequisitionLoading, setIsRequisitionLoading] = useState(true);

  // requisition data to display to RC
  const [rawRCRequisitionData, setRawRCRequisitionData] = useState([]);
  console.log(rawRCRequisitionData);
  const [isRCRequisitionData, setIsRCRequisitionData] = useState(false);
  const [RCRequisitionEntries, setRCRequisitionEntries] = useState(5);
  const [RCRequisitionCount, setRCRequisitionCount] = useState(0);
  const [RCRequisitionpageCount, setRCRequisitionpageCount] = useState(0);
  const [isRCRequisitionLoading, setisRCRequisitionLoading] = useState(true);

  // requisition data to display to SME
  const [rawSMERequisitionData, setRawSMERequisitionData] = useState([]);
  console.log(rawSMERequisitionData);
  const [isSMERequisitionData, setIsSMERequisitionData] = useState(false);
  const [SMERequisitionEntries, setSMERequisitionEntries] = useState(5);
  const [SMERequisitionCount, setSMERequisitionCount] = useState(0);
  const [SMERequisitionpageCount, setSMERequisitionpageCount] = useState(0);
  const [isSMERequisitionLoading, setisSMERequisitionLoading] = useState(true);

  // To view all requisition and with filter of accepted/rejected
  const [selectedRequisitionStatus, setSelectedRequisitionStatus] = useState({
    value: "All",
    label: "All",
  });

  const [isAllRequistionData, setIsAllRequistionData] = useState(false);
  const [rawAllRequistionData, setRawAllRequistionData] = useState([]);
  const [allRequisitionEntries, setAllRequistionEntries] = useState(5);
  const [allRequisitionCount, setAllRequisitionCount] = useState(null);
  const [allRequistionPageCount, setAllRequisitionPageCount] = useState(0);
  const [isAllRequisitionLoading, setIsAllRequisitionLoading] = useState(false);

  // Eagle eye view.
  const [isEagleEyeViewData, setIsEagleEyeViewData] = useState(false);
  const [eagleEyeViewData, setEagleEyeViewData] = useState([]);
  const [eagleEyeViewEntries, setEagleEyeViewEntries] = useState(5);
  const [eagleEyeViewCount, setEagleEyeViewCount] = useState(null);
  const [eagleEyeViewPageCount, setEagleEyeViewPageCount] =
    useState(0);
  const [isEagleEyeViewLoading, setIsEagleEyeViewLoading] =
    useState(true);

  const [isDropdownReqLoading, setIsDropdownReqLoading] = useState(false);

  // requested requisition for the particular department to display.
  const [isRequisitionByDepartmentData, setIsRequisitionByDepartmentData] = useState(false);
  const [requisitionByDepartmentData, setRequisitionByDepartmentData] = useState([]);
  const [requisitionByDepartmentEntries, setRequisitionByDepartmentEntries] = useState(5);
  const [requisitionByDepartmentCount, setRequisitionByDepartmentCount] = useState(null);
  const [requisitionByDepartmentPageCount, setRequisitionByDepartmentPageCount] = useState(0);
  const [isRequisitionByDepartmentLoading, setIsRequisitionByDepartmentLoading] = useState(true);

  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawPendingCandidateDetails, setRawPendingCandidateDetails] = useState([]);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  const getPendingCandidateDetails = async (
    page,
    reqId,
    searchCandidate,
    access,
    shortlisted_type
  ) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      url =
        VARIABLES.url +
        `/api/hrd-md-approval-pending-candidate/?entries=${candidateEntries}&page=${page}&requisition_id=${reqId}&approval_type=${shortlisted_type}&event=${searchCandidate}`;
    } else {
      url =
        VARIABLES.url +
        `/api/hrd-md-approval-pending-candidate/?entries=${candidateEntries}&page=${page}&requisition_id=${reqId}&approval_type=${shortlisted_type}`;
    }
    console.log(url);

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawPendingCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawPendingCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      } else {
        setIsCandidateData(true);
      }
    }
  };

  const handlePendingCandidateClick = async (data, reqId, searchCandidate, sort, access, from) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPendingCandidateDetails(
      currentPage,
      reqId,
      searchCandidate,
      access,
      from
    );
  };

  // "getRequisitionByUserData" API call to get requisition of that particular user - My Requisition Tab
  const getRequisitionByUserData = async (page, searchRequest, access) => {
    setIsRequisitionByUserLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requested_user_id=${userID}&entries=${requisitionByUserEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requested_user_id=${userID}&entries=${requisitionByUserEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsRequisitionByUserLoading(false);
      if (response.length !== 0) {
        setIsRequisitionByUserData(false);
        setRequisitionByUserData(res.data.data);
      } else {
        setIsRequisitionByUserData(true);
        setRequisitionByUserData(res.data.data);
      }
      const total = res.data.count;
      setRequisitionByUserCount(total);
      setRequisitionByUserPageCount(
        Math.ceil(total / requisitionByUserEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRequisitionByUserLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRequisitionByUserData(false);
      } else {
        setIsRequisitionByUserData(true);
      }
    }
  };

  const handleRequisitionByUserPageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequisitionByUserData(
      currentPage,
      searchRequest,
      access
    );
  };

  // "getRawRequestedJobData" API call to get ALL requisitions data
  const getRawRequestedJobData = async (page, searchRequest, access) => {
    setIsLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requestedEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requestedEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsLoading(false);
      if (response.length !== 0) {
        setIsData(false);
        setRawRequestedJob(res.data.data);
        console.log(rawRequestedJob);
      } else {
        setIsData(true);
        setRawRequestedJob(res.data.data);
      }
      const total = res.data.count;
      setRequestedCount(total);
      setRequestedPageCount(Math.ceil(total / requestedEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsData(false);
      } else {
        setIsData(true);
      }
    }
  };

  const handlePageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRawRequestedJobData(
      currentPage,
      searchRequest,
      access
    );
  };

  // "getRequestedRequisitionData" API call to get requisition of all managers to accept and reject. (All requisition data that are requested by managers) - Requisition Approval Tab
  const getRequestedRequisitionData = async (page, searchRequest, access) => {
    setIsRequisitionLoading(true);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requisition_acceptance=0&entries=${requisitionEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requisition_acceptance=0&entries=${requisitionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsRequisitionLoading(false);
      if (response.length !== 0) {
        setIsRequisitionData(false);
        setRawRequisitionData(res.data.data);
      } else {
        setIsRequisitionData(true);
        setRawRequisitionData(res.data.data);
      }
      const total = res.data.count;
      setRequisitionCount(total);
      setRequisitionPageCount(Math.ceil(total / requisitionEntries));
    } catch (error) {
      console.log(error);
      setIsRequisitionLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRequisitionData(false);
      } else {
        setIsRequisitionData(true);
      }
    }
  };

  const handleRequisitionPageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequestedRequisitionData(
      currentPage,
      searchRequest,
      access
    );
  };

  // getRCRequisitionData API call to get requisition for RC - In RC Tab
  const getRCRequisitionData = async (page, searchRequest, access) => {
    setisRCRequisitionLoading(true);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=rc&entries=${RCRequisitionEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=rc&entries=${RCRequisitionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      console.log(response);
      setisRCRequisitionLoading(false);
      if (response.length !== 0) {
        setIsRCRequisitionData(false);
        setRawRCRequisitionData(res.data.data);
      } else {
        setIsRCRequisitionData(true);
        setRawRCRequisitionData(res.data.data);
      }
      const total = res.data.count;
      setRCRequisitionCount(total);
      setRCRequisitionpageCount(Math.ceil(total / RCRequisitionEntries));
    } catch (error) {
      console.log(error);
      setisRCRequisitionLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRCRequisitionData(false);
      } else {
        setIsRCRequisitionData(true);
      }
    }
  };

  const handleRCRequisitionPageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data.selected);
    let page = data.selected + 1;
    const commentsFormServer = await getRCRequisitionData(
      page,
      searchRequest,
      access
    );
  };

  // getSMERequisitionData API call to get requisition for SME - In SME Tab
  const getSMERequisitionData = async (page, searchRequest, access) => {
    setisSMERequisitionLoading(true);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=sme&entries=${SMERequisitionEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=sme&entries=${SMERequisitionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      console.log(response);
      setisSMERequisitionLoading(false);
      if (response.length !== 0) {
        setIsSMERequisitionData(false);
        setRawSMERequisitionData(res.data.data);
      } else {
        setIsSMERequisitionData(true);
        setRawSMERequisitionData(res.data.data);
      }
      const total = res.data.count;
      setSMERequisitionCount(total);
      setSMERequisitionpageCount(Math.ceil(total / SMERequisitionEntries));
    } catch (error) {
      console.log(error);
      setisSMERequisitionLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsSMERequisitionData(false);
      } else {
        setIsSMERequisitionData(true);
      }
    }
  };

  const handleSMERequisitionPageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data.selected);
    let page = data.selected + 1;
    const commentsFormServer = await getSMERequisitionData(
      page,
      searchRequest,
      access
    );
  };

  // To view all requisition and with filter of accepted/rejected/all - All requition Tab
  const getAllRequistion = async (page, searchRequest, access) => {
    setIsAllRequisitionLoading(true);
    let url;
    if (
      selectedRequisitionStatus &&
      searchRequest &&
      selectedRequisitionStatus.value !== "All"
    ) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}&requisition_acceptance=${selectedRequisitionStatus.value}&event=${searchRequest}`;
    } else if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}&event=${searchRequest}`;
    } else if (
      selectedRequisitionStatus &&
      selectedRequisitionStatus.value !== "All"
    ) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}&requisition_acceptance=${selectedRequisitionStatus.value}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsAllRequisitionLoading(false);
      if (response.length !== 0) {
        setIsAllRequistionData(false);
        setRawAllRequistionData(res.data.data);
      } else {
        setIsAllRequistionData(true);
        setRawAllRequistionData(res.data.data);
      }
      const total = res.data.count;
      setAllRequisitionCount(total);
      setAllRequisitionPageCount(Math.ceil(total / allRequisitionEntries));
    } catch (error) {
      console.log(error);
      setIsAllRequisitionLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsAllRequistionData(false);
      } else {
        setIsAllRequistionData(true);
      }
    }
  };

  const handleQuestionClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAllRequistion(
      currentPage,
      searchRequest,
      access
    );
  };

  // Eagle eye view data
  const getEagleEyeViewData = async (page, searchRequest, access) => {
    setIsEagleEyeViewLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    // if (searchRequest) {
    //   url =
    //     VARIABLES.url +
    //     `/api/requestrequisition/?requested_user_id=${userID}&entries=${eagleEyeViewEntries}&page=${page}&event=${searchRequest}`;
    // } else {

    url =
      VARIABLES.url +
      `/api/requisition_life_cycle/?entries=${eagleEyeViewEntries}&page=${page}`;
    // }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsEagleEyeViewLoading(false);
      if (response.length !== 0) {
        setIsEagleEyeViewData(false);
        setEagleEyeViewData(res.data.data);
      } else {
        setIsEagleEyeViewData(true);
        setEagleEyeViewData(res.data.data);
      }
      const total = res.data.count;
      setEagleEyeViewCount(total);
      setEagleEyeViewPageCount(
        Math.ceil(total / eagleEyeViewEntries)
      );
    } catch (error) {
      console.log(error);
      setIsEagleEyeViewLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsEagleEyeViewData(false);
      } else {
        setIsEagleEyeViewData(true);
      }
    }
  };

  const handleEagleEyeViewPageClick = async (data, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getEagleEyeViewData(
      currentPage,
      searchRequest,
      access
    );
  };


  // "getRequisitionByDepartmentData" API call to get requisition of that particular department - My Requisition Tab
  const getRequisitionByDepartmentData = async (department_id, page, searchRequest, access) => {
    setIsRequisitionByDepartmentLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requisitionByDepartmentEntries}&page=${page}&department_id=${department_id}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requisitionByDepartmentEntries}&page=${page}&department_id=${department_id}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsRequisitionByDepartmentLoading(false);
      if (response.length !== 0) {
        setIsRequisitionByDepartmentData(false);
        setRequisitionByDepartmentData(res.data.data);
      } else {
        setIsRequisitionByDepartmentData(true);
        setRequisitionByDepartmentData(res.data.data);
      }
      const total = res.data.count;
      setRequisitionByDepartmentCount(total);
      setRequisitionByDepartmentPageCount(
        Math.ceil(total / requisitionByDepartmentEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRequisitionByDepartmentLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRequisitionByDepartmentData(false);
      } else {
        setIsRequisitionByDepartmentData(true);
      }
    }
  };

  const handleRequisitionByDepartmentPageClick = async (data, department_id, searchRequest) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequisitionByDepartmentData(
      department_id,
      currentPage,
      searchRequest,
      access
    );
  };


  return (
    <div>
      <RequestedJobOpeningContext.Provider
        value={{
          rawPendingCandidateDetails,
          getPendingCandidateDetails,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handlePendingCandidateClick,
          isCandidateData,
          isCandidateLoading,

          requisitionByUserData,
          getRequisitionByUserData,
          requisitionByUserEntries,
          requisitionByUserPageCount,
          setRequisitionByUserEntries,
          requisitionByUserCount,
          handleRequisitionByUserPageClick,
          isRequisitionByUserData,
          isRequisitionByUserLoading,

          rawRequestedJob,
          getRawRequestedJobData,
          setRawRequestedJob,
          requestedEntries,
          requestPageCount,
          setRequestedEntries,
          requestedCount,
          handlePageClick,
          isData,
          isLoading,

          rawRequisitionData,

          rawRCRequisitionData,
          setRawRCRequisitionData,
          getRCRequisitionData,
          RCRequisitionEntries,
          RCRequisitionpageCount,
          setRCRequisitionEntries,
          RCRequisitionCount,
          handleRCRequisitionPageClick,
          isRCRequisitionData,
          isRCRequisitionLoading,

          rawSMERequisitionData,
          getSMERequisitionData,
          SMERequisitionEntries,
          SMERequisitionpageCount,
          setSMERequisitionEntries,
          SMERequisitionCount,
          handleSMERequisitionPageClick,
          isSMERequisitionData,
          isSMERequisitionLoading,

          getRequestedRequisitionData,
          requisitionEntries,
          requisitionPageCount,
          setRequisitionEntries,
          requisitionCount,
          handleRequisitionPageClick,
          isRequisitionData,
          isRequisitionLoading,

          setSelectedRequisitionStatus,
          selectedRequisitionStatus,
          isAllRequistionData,
          rawAllRequistionData,
          getAllRequistion,
          allRequisitionEntries,
          allRequistionPageCount,
          setAllRequistionEntries,
          allRequisitionCount,
          handleQuestionClick,
          isAllRequisitionLoading,

          eagleEyeViewData,
          getEagleEyeViewData,
          eagleEyeViewEntries,
          eagleEyeViewPageCount,
          setEagleEyeViewEntries,
          eagleEyeViewCount,
          handleEagleEyeViewPageClick,
          isEagleEyeViewData,
          isEagleEyeViewLoading,

          isDropdownReqLoading,

          requisitionByDepartmentData,
          getRequisitionByDepartmentData,
          requisitionByDepartmentEntries,
          requisitionByDepartmentPageCount,
          setRequisitionByDepartmentEntries,
          requisitionByDepartmentCount,
          handleRequisitionByDepartmentPageClick,
          isRequisitionByDepartmentData,
          isRequisitionByDepartmentLoading
        }}
      >
        {children}
      </RequestedJobOpeningContext.Provider>
    </div>
  );
}

export default RequestedJobOpeningProvider;
