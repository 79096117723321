import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";
import Swal from "sweetalert2";
import axios from "axios";
import { VARIABLES } from "../../Constants";

const WhatsAppNotification = () => {
    const { isAccess } = useContext(RefreshTokenContext);
    const [searchWhatsAppNotification, setSearchWhatsAppNotification] = useState("");
    const [submitMessage, setSubmitMessage] = useState(false);

    const userID = secureLocalStorage.getItem("userID");

    const privileges = secureLocalStorage.getItem("privileges");

    const {
        rawWhatsAppNotification,
        setRawWhatsAppNotification,
        getWhatsAppNotificationData,
        whatsAppNotificationEntries,
        whatsAppNotificationPageCount,
        setWhatsAppNotificationEntries,
        whatsAppNotificationCount,
        handleWhatsAppNotificationClick,
        isWhatsAppNotificationData,
        isWhatsAppNotificationLoading,
        setIsWhatsAppNotificationLoading,
    } = useContext(NotificationsContext);

    const handleEntries = (event) => {
        setWhatsAppNotificationEntries(event.target.value);
    };

    const onChangeHandler = (event) => {
        setSearchWhatsAppNotification(event.target.value);
    };

    const onSearchHandler = (method, event) => {
        event.preventDefault();
        if (method === "search") {
            getWhatsAppNotificationData(1, searchWhatsAppNotification);
        } else {
            getWhatsAppNotificationData(1);
            setSearchWhatsAppNotification("");
        }
    };

    const handleWhatsAppStatus = (updatedStatus, index) => {
        console.log(updatedStatus);
        console.log(index);
        setRawWhatsAppNotification((prevActivityStatus) => {
            const updatedPostings = [...prevActivityStatus];
            updatedPostings[index].is_active = updatedStatus;
            return updatedPostings;
        });

        var user = JSON.stringify({
            is_active: updatedStatus,
            updated_by_id: userID,
        });

        console.log(user);
        const url =
            VARIABLES.url + `/api/whatsapp-notification-config/${rawWhatsAppNotification[index]?.id}/?type=notification_status`;
        console.log(url);

        var config = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: user,
        };
        setIsWhatsAppNotificationLoading(true)
        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setSubmitMessage({
                    type: "success",
                    icon: "check",
                    message: updatedStatus ? "Notification is active" : "Notification is inactive",
                });
                setIsWhatsAppNotificationLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsWhatsAppNotificationLoading(false)
                setRawWhatsAppNotification((prevActivityStatus) => {
                    const reversePostings = [...prevActivityStatus];
                    reversePostings[index].is_active = !updatedStatus;
                    return reversePostings;
                });
                setSubmitMessage({
                    type: "danger",
                    icon: "cross",
                    message: "Failed to change notification status",
                });
            });
    };

    const handleWhatsAppStatusAlert = (isChecked, index) => {
        console.log(isChecked)
        Swal.fire({
            text: `Are you sure to ${isChecked ? 'active' : 'deactivate'} this notification?`,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleWhatsAppStatus(isChecked, index);
            } else {
            }
        });
    };

    useEffect(() => {
        if (isAccess) {
            let access = secureLocalStorage.getItem("access");
            getWhatsAppNotificationData(1, searchWhatsAppNotification, access);
        } else {
            let access = secureLocalStorage.getItem("access");
            getWhatsAppNotificationData(1, searchWhatsAppNotification, access);
        }
    }, [whatsAppNotificationEntries, isAccess]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            {/* <WelcomeMessage /> */}
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                        <h3 className="nk-block-title page-title">WhatsApp Notification</h3>
                        {isWhatsAppNotificationLoading ? (
                            <div class="spinner-border text-primary ms-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="card">
                    <div className="card-inner-group">
                        <div className="card-inner border-bottom-0">
                            <div className="form-control-wrap">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={searchWhatsAppNotification}
                                            className="form-control"
                                            placeholder="Search by stage, message template, status  (e.g. active / inactive)"
                                            onChange={onChangeHandler}
                                            id="text-form-input-search"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-dim"
                                                id="button-button-search"
                                                onClick={(e) => onSearchHandler("search", e)}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-dim"
                                                id="button-button-view"
                                                onClick={(e) => onSearchHandler("view", e)}
                                            >
                                                Clear Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <div className="nk-tb-list">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col">
                                                <span>Stage</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Message Template</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Status</span>
                                            </div>

                                            {/* {privileges.includes("notification_mapper_update") ? ( */}
                                            <div className="nk-tb-col">
                                                <span>Edit</span>
                                            </div>
                                            {/* ) : (
                                                " "
                                            )} */}
                                        </div>

                                        {rawWhatsAppNotification.length > 0 ? (
                                            rawWhatsAppNotification.map((post, index) => (
                                                <div className="nk-tb-item" key={index}>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-product">
                                                            <span className="title">{post.stage || "-"}</span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            {post.template_message || "-"}
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`customSwitchWhatsApp${index}`}
                                                                checked={post?.is_active === true}
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    handleWhatsAppStatusAlert(isChecked, index);
                                                                }}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                id="button-button-status"
                                                                htmlFor={`customSwitchWhatsApp${index}`}
                                                            ></label>
                                                        </div>
                                                    </div>

                                                    {/* {privileges.includes("notification_mapper_update") ? ( */}
                                                    <div className="nk-tb-col">
                                                        <NavLink
                                                            to={"/editWhatsAppNotification"}
                                                            state={{ editpost: post }}
                                                            className="btn btn-sm btn-primary"
                                                            id="button-button-editWhatsAppNotification"
                                                        >
                                                            <em className="icon ni ni-edit"></em>
                                                        </NavLink>
                                                    </div>
                                                    {/* ) : (
                                                        " "
                                                    )} */}
                                                </div>
                                            ))
                                        ) : isWhatsAppNotificationData ? (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Good job! You are all caught up.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Loading.....</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="nk-block-between-md g-3">
                                <div className="g">
                                    <ReactPaginate
                                        key={whatsAppNotificationPageCount}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={whatsAppNotificationPageCount}
                                        marginPagesDisplayed={2}
                                        onPageChange={(data) =>
                                            handleWhatsAppNotificationClick(
                                                data,
                                                searchWhatsAppNotification
                                            )
                                        }
                                        containerClassName={
                                            "pagination justify-content-center justify-content-md-start"
                                        }
                                        pageClassName={"paginate_button page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"paginate_button page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                                <div className="g">
                                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                        <div>
                                            Total entries:
                                            <span className="fw-bold">
                                                {whatsAppNotificationCount === null
                                                    ? 0
                                                    : whatsAppNotificationCount}
                                            </span>
                                        </div>
                                        <div>No of entries per page:</div>
                                        <div>
                                            <select
                                                className="form-select js-select2"
                                                data-search="on"
                                                data-dropdown="xs center"
                                                value={whatsAppNotificationEntries}
                                                onChange={handleEntries}
                                                id="select-form-enteries"
                                            >
                                                <option value="5">5</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhatsAppNotification;
