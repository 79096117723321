import React, { useEffect, useState, useContext } from "react";
import DualListBox from "react-dual-listbox";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { LoginContext } from "../../../Context/LoginContext";
import { SettingContext } from "../../../Context/SettingsContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import Swal from "sweetalert2";

const RolesEdit = () => {
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const { RefreshToken } = useContext(RefreshTokenContext);
  const { privilege } = useContext(LoginContext);
  console.log(privilege);
  const { getRole } = useContext(SettingContext);

  const [selected, setSelected] = useState([]);
  console.log(selected);

  const [activeStatus, setActiveStatus] = useState(1);

  const onChange = (value) => {
    setSelected(value);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    console.log(selected);
    var roles = JSON.stringify({
      role_name: data.role_name,
      privilege_id: selected,
      description: data.description,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/roles/${location.state.editpost.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: roles,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getRole();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Role Updated Successfully.",
        });
        reset();
        setTimeout(() => {
          navigate("/roles");
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          RefreshToken();
        }
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to Update the Role.",
        });
      });
  };

  const deleteRole = () => {
    const access = secureLocalStorage.getItem("access");

    const url = VARIABLES.url + `/api/roles/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    axios(config)
      .then((response) => {
        getRole();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Role Deleted Successfully!",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          RefreshToken();
        }
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the Role.",
        });
        console.log(error);
      });
  };

  const deleteRoleAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this Role!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRole();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedData = location.state.editpost;
    console.log(location.state.editpost);
    if (editedData) {
      var defaultValues = {};
      defaultValues.role_name = editedData.role_name;
      defaultValues.description = editedData.description;
      let selectedPri = editedData.privilege.map((pr) => pr.id);
      setSelected(selectedPri);
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Edit Role</h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          {privileges.includes("role_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleterole"
                  checked={activeStatus}
                  onChange={() => {
                    deleteRoleAlert(location.state?.editpost?.id);
                  }} />
                <label
                  className="custom-control-label"
                  id="button-button-roledelete"
                  htmlFor="submit-button-deleterole">
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div class="row mb-3">
                <label class="col-sm-2 form-label fs-6">
                  Role Name : <span className="text-danger">*</span>
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="text-forms-role_name"
                    placeholder="Enter role name"
                    {...register("role_name", {
                      required: true,
                    })} />
                  {errors.role_name && (
                    <span className="fs-8 text-danger">
                      Role name is required
                    </span>
                  )}
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-2 form-label fs-6">Description :</label>
                <div class="col-sm-8">
                  <textarea
                    className="form-control"
                    id="textarea-forms-description"
                    placeholder="Enter description"
                    {...register("description")} />
                </div>
              </div>
              <div className="row g-4">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label fs-6">
                      Privileges : <span className="text-danger">*</span>
                    </label>
                    <DualListBox
                      id="dualbox-list-privilege"
                      canFilter={true}
                      options={privilege}
                      selected={selected}
                      onChange={onChange}
                      required />
                    <span className="fs-6 text-info fw-medium">
                      Double-click on the privilege to assign it.
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-submitrole">
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}>
                  <em className={`icon ni ni-${submitMessage.icon}-circle`}></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RolesEdit;
