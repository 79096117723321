import React, { Suspense, useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "./SequenceContextProvider";
import DefaultDepends from "./DefaultDepends";
import DefaultForm from "./DefaultForm";
import { WorkflowContext } from "../../Context/WorkflowContext";

const NestedStepperForm = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  console.log(privileges)
  console.log(props.data);
  // Context API hook for getting current state of sequence context
  // const sequenceState = useSelector((state) => state.sequence);
  const { currentSequence, sequenceList, setLevel, currentForm } =
    useContext(SequenceContext);

  console.log(currentForm)

  const { workflowSession } = useContext(WorkflowContext);

  const session_store = workflowSession; //Using API data

  // Loading Forms dynamically using react lazy
  // parameter 'form' refers to component name
  const loadForm = (form) => {
    if (form.form !== null && form.form !== undefined && currentForm?.form !== null) {
      if (currentForm?.id === form?.id) {
        const Form = React.lazy(() => import(`../${form?.form?.form_path}`));
        return (props) => <Form {...props} formId={form} />;
        // return Form;
      }
    }
    return React.lazy(() => import(`./EmptyForm`));
  };

  return (
    <>
      <ul className="nav nav-tabs" id="nav-tab" role="tablist">
        {props?.data.map((tab, i) => {
          let changetab = -1;
          if (props?.level === 0) {
            changetab = sequenceList.findIndex(
              (sequence) => sequence[props?.level] === i
            );
          } else {
            changetab = sequenceList.findIndex((sequence) => {
              // Check if the sequence length is at least as long as the currentSequence
              if (sequence.length >= props.level + 1) {
                // Check if the elements of the sequence match the currentSequence up to targetIndex
                for (let i = 0; i < props.level; i++) {
                  if (sequence[i] !== currentSequence[i]) {
                    return false;
                  }
                }
                // Check if the element at targetIndex matches targetValue
                if (sequence[props.level] === i) {
                  return true;
                }
              }
              return false;
            });
          }
          return (
            <li
              key={i}
              className={`nav-item ${i === currentSequence[props.level] ? "active" : ""
                }`}
              id={`nav-${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#tab${tab.id}`}
              type="button"
              role="tab"
            >
              <a
                className="nav-link"
                onClick={() => {
                  if (changetab !== -1) {
                    setLevel(changetab);
                  }
                }}
              >
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content" id="nav-tabContent">
        {props.data.map((tab, i) => {
          const Form = loadForm(tab);

          // should_render_form checks the form name available in privilleges or not.
          const shouldRenderForm = tab.form
            ? privileges.includes(tab.form.form_name)
            : true;

          let dependsOn;
          {
            tab.depends_on.map((depend) => {
              if (depend === 0) {
                dependsOn = true;
              } else {
                dependsOn = tab.depends_on.every((dependsOn) =>
                  session_store.some((session) => session.tab_id === dependsOn)
                );
              }
            });
          }

          return (
            <div
              key={i}
              className={`tab-pane fade ${
                // Open tab-content of currently active tab
                // That is why syntax is same as with current tab
                i === currentSequence[props.level] ? "show active" : ""
                }`}
              id={tab.name}
              role="tabpanel"
              tabIndex="0"
            >
              {/* Check if current tab having more tabs nested */}
              {tab?.tabs.length !== 0 ? (
                // If yes: pass the tabs of current tab recursively and increse level by one
                (console.log("Inside NestedStepperForm:", tab),
                  (<NestedStepperForm data={tab.tabs} level={props.level + 1} />))
              ) : (
                // If not: Render the form for current component
                <Suspense fallback={<div>Loading...</div>}>
                  {shouldRenderForm ? (
                    dependsOn ? (
                      (console.log("Inside Form:", tab), (<Form tabs={tab} />))
                    ) : (
                      <DefaultDepends />
                    )
                  ) : (
                    <DefaultForm />
                  )}
                </Suspense>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NestedStepperForm;
