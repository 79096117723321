import React, { useContext, useEffect, useState } from "react";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import CandidatePoolResumes from "../CandidatePoolResumes/CandidatePoolResumes";
import { useLocation } from "react-router-dom";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

const ViewRCCandidatesResume = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const {
    rawRCResumeData,
    isRCResumeLoading,
    getAllCandidateResume,
    getRCCandidateResume,
  } = useContext(ViewResumeContext);

  console.log("rawRCResumeData: ", rawRCResumeData)

  const location = useLocation();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [sortedRCResumeData, setSortedRCResumeData] = useState([null]);
  console.log("sortedRCResumeData:", sortedRCResumeData)
  const [IndexOfResume, setIndexOfResume] = useState(null);

  useEffect(() => {
    console.log(rawRCResumeData);
    const index_of_resume = rawRCResumeData.findIndex(
      (obj) => obj.id === location.state.candidateID
    );
    setIndexOfResume(index_of_resume);
    setSortedRCResumeData(rawRCResumeData);
  }, [rawRCResumeData, location.state.candidateID]);

  // useEffect(() => {
  //   if (isAccess) {
  //     let access = secureLocalStorage.getItem("access");
  //     getRCCandidateResume(access);
  //   }
  // }, [isAccess]);

  return (
    <>
      <CandidatePoolResumes
        rawResumeData={sortedRCResumeData}
        isLoading={isRCResumeLoading}
        getResumeData={getAllCandidateResume}
        reqID={location.state.reqID}
        from={location.state?.from}
        setSubmitMessage={setSubmitMessage}
        submitMessage={submitMessage}
        shortlisting_type={"RC"}
        resume_index={IndexOfResume}
      />
    </>
  );
};

export default ViewRCCandidatesResume;
