import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../Constants";
import { useGoogleLogin } from "@react-oauth/google";
import { LoginContext } from "../Context/LoginContext";

const Login = (props) => {
  const { setUser } = useContext(LoginContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const onSubmit = (data) => {
    let user = new FormData();
    user.append("email", data.email);
    user.append("password", data.password);
    user.append("portal_unique_id", VARIABLES.rms_login);
    user.append("is_google_login", "False");

    const url = VARIABLES.url + "/api/login/";
    console.log(url);

    let reqOptions = {
      url: url,
      method: "POST",
      data: user,
    };

    setIsLoading(true);
    axios
      .request(reqOptions)
      .then((response) => {
        const userData = response.data.data[0].user_details;
        const loginStatus = response.status;
        if (loginStatus === 200) {
          setIsLoading(false);
          props.loginHandler(userData);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setTimeout(() => {
          setIsLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error.response?.data?.message || 'Something went wrong. Please check your network and try again.',
          });
        }, 5000);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      const user = codeResponse;
      checkGoogleAuthentication(user);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const checkGoogleAuthentication = (user) => {
    console.log(user);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          let profile = res.data;
          console.log(profile);
          let user = new FormData();
          user.append("email", profile.email);
          user.append("portal_unique_id", VARIABLES.rms_login);
          user.append("first_name", profile.given_name);
          user.append("last_name", profile.family_name);
          user.append("is_google_login", "True");

          const url = VARIABLES.url + "/api/login/";
          console.log(url);

          for (var pair of user.entries()) {
            console.log(pair);
          }

          let reqOptions = {
            url: url,
            method: "POST",
            data: user,
          };

          setIsLoading(true);
          axios
            .request(reqOptions)
            .then((response) => {
              const userData = response.data.data[0].user_details;
              const loginStatus = response.status;
              if (loginStatus === 200) {
                setIsLoading(false);
                props.loginHandler(userData);
              }
            })
            .catch((error) => {
              console.log(error.response);
              setTimeout(() => {
                setIsLoading(false);
                setSubmitMessage({
                  type: "danger",
                  icon: "cross",
                  message: error.response?.data?.message,
                });
              }, 5000);
            });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="nk-app-root">
        <img
          src={require("../../Resources/images/RMS-bg.jpg")}
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            backgroundSize: "cover",
          }}
        />
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="card p-4">
                  <div className="card-inner bg-blue rounded-3">
                    <img src={require("../../Resources/images/RMS-Logo.png")} />
                  </div>
                  <div className="card-inner p-0 mt-4 card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Login</h4>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row" style={{ gap: "2rem" }}>
                        <div className="col-sm-12">
                          <label className="visually-hidden" htmlFor="email">
                            Email
                          </label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <em className="icon ni ni-mail fs-5"></em>
                            </div>
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email-forms-email"
                              placeholder="Enter your email address"
                              {...register("email", {
                                required: "Email is required",
                                validate: (value) =>
                                  value === value.toLowerCase() ||
                                  "Email should only contain lowercase letters",
                              })}
                            />
                          </div>
                          {errors.email && (
                            <span className="fs-8 text-danger">
                              {errors?.email?.message}
                            </span>
                          )}
                        </div>
                        <div className="col-sm-12">
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <em className="icon ni ni-lock-alt fs-5"></em>
                                </span>
                              </div>
                              <input
                                type={`${showPassword === true ? "text" : "password"
                                  }`}
                                className={`${showPassword === true
                                  ? "form-control form-control-lg valid is-shown"
                                  : "form-control form-control-lg"
                                  }`}
                                id="password-forms-password"
                                placeholder="Enter your password"
                                {...register("password", { required: true })}
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text p-0"
                                  style={{ zIndex: "100" }}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    className={`${showPassword === true
                                      ? "form-icon form-icon-right passcode-switch lg is-shown"
                                      : "form-icon form-icon-right passcode-switch lg"
                                      }`}
                                    data-target="password"
                                  >
                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                  </a>
                                </span>
                              </div>
                            </div>
                            {errors.password && (
                              <span className="text-danger">
                                Password is required
                              </span>
                            )}
                            <div className="form-note-s2 pt-4">
                              <a
                                href={VARIABLES.forgot_password_link}
                                target="_blank"
                              >
                                Forgot Password ?
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            {isLoading === true ? (
                              <button
                                id="button-loading"
                                className="btn text-dark"
                              >
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-lg btn-info btn-block fs-4 fw-normal"
                                id="submit-button-login"
                                atl="signin"
                              >
                                Login
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <ul className="nav justify-center gx-4">
                      <li className="nav-item">
                        <a
                          className="btn btn-primary"
                          href="#"
                          onClick={() => login()}
                          id="button-google-authentication"
                        >
                          Sign in with Google{" "}
                          <em className="ni ni-google ms-1"></em>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full mb-4">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-dark">
                          &copy; 2024 Fluidscapes Consultant Pvt.Ltd.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
