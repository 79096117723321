import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { SendOfferContext } from "../../../Context/SendOfferContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";

const SendOffer = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;

  console.log(`Offer later`);
  console.log(formId);

  const { isAccess } = useContext(RefreshTokenContext);
  console.log(formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Secure Local Storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  // Stepper (PREV/NEXT)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  console.log(workflowSession);

  //stores data of candidates previous offer data
  const { sendOffer, isSendOfferDataLoading, getSendOfferData } =
    useContext(SendOfferContext);
  console.log(sendOffer);

  // "selectedOffer" saves (If there is already entry of the candidate in send offer
  // or not. If yes => then it saves the details of "SendOffer" table details into "selectedOffer")
  const [selectedOffer, setSelectedOffer] = useState([]);

  const [isEditable, setIsEditable] = useState(sendOffer?.length === 0);

  const [filePreview, setFilePreview] = useState(null);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      nextLevel();
    }
  };

  //checks that is there an offer already given to this candidate or not
  //if yes : saves details in "selectedOffer" in state
  const handleOfferCheck = () => {
    const existingAssignment = sendOffer?.find(
      (OfferItem) => OfferItem?.candidate_job?.id === candidate_id
    );
    setSelectedOffer(existingAssignment);
  };

  //Form submission
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };
    // if (selectedOffer.length > 0) {
    //   let user = new FormData();
    //   user.append("candidate_job_id", candidate_job_id);
    //   user.append("requisition_id", requisition_id);
    //   user.append("offer_attachment", data.offer_attachment);
    //   user.append("updated_by_id", userID);
    //   user.append("workflow_session", JSON.stringify(workflow_session));

    //   const url = VARIABLES.url + `/api/sendoffer/${sendOffer.id}/`;
    //   console.log(url);
    //   let config = {
    //     method: "patch",
    //     maxBodyLength: Infinity,
    //     headers: {
    //       "Authorization": `Bearer ${access}`
    //     },
    //     url: url,
    //     data: user,
    //   };

    //   for (var pair of user.entries()) {
    //     console.log(pair);
    //   }

    //   setSubmitLoading(true);
    //   axios.request(config).then((response) => {
    //   console.log(JSON.stringify(response.data));
    //     if (response.data) {
    //       setSubmitLoading(false);
    //       setSubmitMessage({
    //         type: "success",
    //         icon: "check",
    //         message: "Offer sent successfully",
    //       });
    //       getWorkflowSession();
    //       nextLevel();
    //     }
    //   }).catch((error) => {
    //     setSubmitLoading(false);
    //     console.log(error);
    //     setSubmitMessage({
    //       type: "danger",
    //       icon: "cross",
    //       message: error?.response?.data?.message || "Failed to send offer",
    //     });
    //   });
    // } else {
    let user = new FormData();
    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("offer_attachment", data?.offer_attachment);
    user.append("created_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    const url = VARIABLES.url + "/api/sendoffer/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: url,
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    console.log(data);
    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          getWorkflowSession(candidate_id, requisition_id);
          getSendOfferData(candidate_id, requisition_id, access);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Offer sent successfully",
          });
          nextLevel();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to send offer",
        });
      });
    // }
  };

  useEffect(() => {
    handleOfferCheck();
  }, []);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getSendOfferData(candidate_id, requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getSendOfferData(candidate_id, requisition_id, access);
    }
  }, [isAccess]);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Offer Letter </h4>
            {isSendOfferDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {sendOffer?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )} */}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
              <div className="row g-4">
                {/* <div className="col-lg-4">
                  <div className="form-group">
                    <label className="form-label">STATUS : </label>
                    <div
                      className="form-control fs-6 fw-bold"
                      style={{
                        backgroundColor:
                          candidateStatus[0]?.md_approval === "Reject"
                            ? "red"
                            : candidateStatus[0]?.md_approval === "Accept"
                            ? "green"
                            : "",
                      }}
                    >
                      {candidateStatus[0]?.md_approval || "-"}
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12"></div> */}
                {/* <div className="nk-block">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Comments</h5>
                  </div>
                  <div className="bq-note">
                    <div className="bq-note-item">
                      <div className="bq-note-text">
                        <p>{candidateStatus[0]?.md_comments || "-"}</p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Upload Offer Letter <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={`offer_attachment`}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value.type === "application/pdf",
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-offerattachment"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                          handleResumeChange(e.target.files);
                        }}
                        disabled={isFormFilled}
                      // disabled={
                      //   !isEditable && sendOffer?.[0]?.offer_attachment
                      // }
                      />
                    )}
                  />
                  <div className="d-flex flex-column">
                    {errors.offer_attachment &&
                      errors.offer_attachment.type === "required" && (
                        <span className="fs-8 text-danger">
                          Offer Letter is required
                        </span>
                      )}
                    {errors.offer_attachment &&
                      errors.offer_attachment.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Please upload your document in pdf format
                        </span>
                      )}
                    {sendOffer[0]?.offer_attachment && (
                      <span className="fs-8 pt-2">
                        Previously uploaded offer letter :
                        <a
                          className="btn btn-primary ms-2"
                          id="button-button-viewresume"
                          href={sendOffer[0]?.offer_attachment}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <em
                            className="icon ni ni-file-pdf"
                            style={{ color: "white" }}
                          ></em>
                        </a>
                      </span>
                    )}
                    {filePreview && (
                      <a
                        className="btn btn-primary mt-2 ms-1"
                        href={filePreview}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview Uploaded File
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete === 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default SendOffer;
