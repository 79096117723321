import React, { useContext, useEffect, useRef, useState } from "react";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import CreateDepartmentModal from "./modals/CreateDepartmentModal";
import CreatePositionModal from "./modals/CreatePositionModal";
import CreateTeamModal from "./modals/CreateTeamModal";
import CreateSeniorityModal from "./modals/CreateSeniorityModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { JobOpeningContext } from "../../Context/JobOpeningContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { MasterTableContext } from "../../Context/MasterTableContext";

const MastersCombinedForm = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const location = useLocation();
  const userID = secureLocalStorage.getItem("userID");
  const post = location.state?.editposition;
  console.log(post);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  console.log(errors)

  const editDescription = watch("job_description");
  const editRequirement = watch("job_requirement");

  const onDescriptionChange = (editDescription) => {
    setValue("job_description", editDescription);
  };
  const onRequirementChange = (editRequirement) => {
    setValue("job_requirement", editRequirement);
  };

  const positionName = watch("position_name");
  const positionDisplayName = watch("position_display_name");

  const [submitMessage, setSubmitMessage] = useState(null);

  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadedFile(file);
    setValue('assignment_document', file);
  };

  const removeFile = () => {
    setUploadedFile(null);
    setValue('assignment_document', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const openFileInNewTab = () => {
    if (uploadedFile) {
      const fileURL = URL.createObjectURL(uploadedFile);
      window.open(fileURL, '_blank');
    }
  };

  const {
    isGenricDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    workflowDropdownData,
    getGenericDropdownData,
  } = useContext(GenericDropdownContext);

  const { RefreshToken } = useContext(RefreshTokenContext);
  const { getDepartmentData } = useContext(MasterTableContext);
  const { getJobDescriptionData } = useContext(JobOpeningContext);

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (positionName === "") {
      reset({
        position_name: e.target.value,
      });
    }
    if (positionDisplayName === "") {
      reset({
        position_display_name: e.target.value,
      });
    }
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    user.append("position_name", data.position_name);
    user.append("position_display_name", data.position_display_name);
    user.append("department", data.department.value);
    user.append("team_id", data.team.value);
    user.append("job_description", editDescription);
    user.append("job_requirement", editRequirement);
    user.append("experience", data.required_experience);
    user.append("location", data.job_location);
    user.append("workflow_id", data.workflow_assigned.value);
    user.append("assignment_title", data.assignment_title);
    user.append("assignment_document", data.assignment_document);
    user.append("created_by_id", userID);

    if (!post) {
      const url = VARIABLES.url + "/api/position/";
      console.log(url);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `Bearer ${access}`,
        },
        data: user,
      };

      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Position created successfully",
          });
          getJobDescriptionData();
          getGenericDropdownData();
          reset();
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 403) {
            setSubmitLoading(true);
            RefreshToken();
          } else {
            setSubmitLoading(false);
            setSubmitMessage({
              type: "danger",
              icon: "cross",
              message: "Failed to create assignment",
            });
          }
        });
    } else {
      const url =
        VARIABLES.url + `/api/position/${location.state.editpost.id}/`;
      console.log(url);

      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `Bearer ${access}`,
        },
        data: user,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Position updated successfully",
          });
          getGenericDropdownData();
          setTimeout(() => {
            navigate("/position");
          }, 2000);
          reset();
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 403) {
            setSubmitLoading(true);
            RefreshToken();
          } else {
            setSubmitLoading(false);
            setSubmitMessage({
              type: "danger",
              icon: "cross",
              message: "Failed to update position",
            });
          }
        });
    }
  };

  useEffect(() => {
    register("job_description", {
      validate: (value) => value !== "<p><br></p>",
      required: true,
    });
  }, [register]);

  useEffect(() => {
    register("job_requirement", {
      validate: (value) => value !== "<p><br></p>",
      required: true,
    });
  }, [register]);

  useEffect(() => {
    if (submitMessage) {
      getDepartmentData();
      const timeoutId = setTimeout(() => {
        setSubmitMessage(null);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [submitMessage]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title">Create Position</h3>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group p-3">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4 mb-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <div className="d-flex">
                    <div className="w-100 me-3">
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={departmentDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.department_display_name,
                            }))}
                            isClearable={true}
                            isLoading={isGenricDropdownDataLoading}
                            id="select-forms-department"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="department"
                        control={control}
                      />
                      {errors.department && (
                        <span className="fs-8 text-danger">
                          Department is required
                        </span>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#createdepartmentmodal"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Team
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <div className="d-flex">
                    <div className="w-100 me-3">
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={teamDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.team_display_name,
                            }))}
                            isClearable={true}
                            isLoading={isGenricDropdownDataLoading}
                            id="select-forms-team"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="team"
                        control={control}
                      />
                      {errors.team && (
                        <span className="fs-8 text-danger">
                          Team is required
                        </span>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#createteammodal"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Position Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter position name"
                    id="text-forms-position_name"
                    {...register("position_name", {
                      required: true,
                      onBlur: handleBlur,
                    })}
                  />
                  {errors.position_name && (
                    <span className="fs-8 text-danger">
                      Position name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Position Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter position display name"
                    id="text-forms-position_display_name"
                    {...register("position_display_name", {
                      required: true,
                      onBlur: handleBlur,
                    })}
                  />
                  {errors.position_display_name && (
                    <span className="fs-8 text-danger">
                      Position display name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Description <span className="text-danger">*</span>
                    </label>
                    {/* <ReactQuill
                      theme="snow"
                      value={editDescription}
                      onChange={onDescriptionChange}
                      id="textarea-forms-Job_description"
                    />
                    <p className="fs-8 text-danger">
                      {errors.job_description && "Job description is required"}
                    </p> */}
                    <Controller
                      name="job_description"
                      control={control}
                      rules={{ required: "Job description is required" }}
                      render={({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value || ""}
                          onChange={field.onChange}
                          id="textarea-forms-job_description"
                        />
                      )}
                    />
                    {errors.job_description && (
                      <p className="fs-8 text-danger">{errors.job_description.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Requirement <span className="text-danger">*</span>
                    </label>
                    {/* <ReactQuill
                      theme="snow"
                      value={editRequirement}
                      onChange={onRequirementChange}
                      id="textarea-forms-job_requirement"
                    />
                    <p className="fs-8 text-danger">
                      {errors.job_requirement && "Job requirement is required"}
                    </p> */}
                    <Controller
                      name="job_requirement"
                      control={control}
                      rules={{ required: "Job requirement is required" }}
                      render={({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value || ""}
                          onChange={field.onChange}
                          id="textarea-forms-job_requirement"
                        />
                      )}
                    />
                    {errors.job_requirement && (
                      <p className="fs-8 text-danger">{errors.job_requirement.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Required Experience (in Years) <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="number-forms-required_experience"
                      placeholder="Enter required experience"
                      onWheel={(e) => e.target.blur()}
                      {...register("required_experience", {
                        required: true,
                      })}
                    />
                    {errors.required_experience &&
                      errors.required_experience.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter your experience
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Location <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-job_location"
                      placeholder="Enter job location"
                      {...register("job_location", {
                        required: true,
                      })}
                    />
                    {errors.job_location && (
                      <span className="fs-8 text-danger">
                        Job Location is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Assign Workflow <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={workflowDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.workflow_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-workflow_assigned"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="workflow_assigned"
                      control={control}
                    />
                    {errors.workflow_assigned && (
                      <span className="fs-8 text-danger">
                        Workflow for job is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Document <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="assignment_document"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <div className="relative">
                          {/* <input
                            className="form-control"
                            id="file-forms-assignment_document"
                            type="file"
                            // onChange={(e) => field.onChange(e.target.files[0])}
                            onChange={(e) => handleFileChange(e.target.files[0])}
                          /> */}
                          <input
                            className="form-control"
                            id="file-forms-assignment_document"
                            type="file"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                          />
                          {uploadedFile && (
                            <div className="mt-2">
                              <span
                                onClick={openFileInNewTab}
                                style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                              >
                                {uploadedFile.name}
                              </span>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm ms-2"
                                onClick={removeFile}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                    {errors.assignment_document && (
                      <span className="fs-8 text-danger">
                        Assignment document is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-assignment_title"
                      placeholder="Enter assignment title"
                      {...register("assignment_title", {
                        required: true,
                      })}
                    />
                    {errors.assignment_title && (
                      <span className="fs-8 text-danger">
                        Assignment title is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createjobassignment"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CreateDepartmentModal />
      <CreatePositionModal />
      <CreateTeamModal />
      <CreateSeniorityModal />
    </>
  );
};

export default MastersCombinedForm;
