import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { CandidateEntriesContext } from "../../Context/CandidateEntriesContext";
import moment from "moment";

const CandidateAcceptanceForReq = () => {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const privileges = secureLocalStorage.getItem("privileges");

  const {
    rawCandidateWithOfferDetails,
    getCandidateWithOfferEntriesData,
    candidateWithOfferEntries,
    candidateWithOfferPageCount,
    setCandidateWithOfferEntries,
    candidateWithOfferCount,
    handleCandidateWithOfferPageClick,
    isCandidateWithOfferData,
    isCandidateWithOfferLoading
  } = useContext(CandidateEntriesContext);
  console.log("rawCandidateWithOfferDetails: ", rawCandidateWithOfferDetails);

  const handleEntries = (event) => {
    setCandidateWithOfferEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getCandidateWithOfferEntriesData(location?.state?.reqId, 1, searchResume);
    } else {
      getCandidateWithOfferEntriesData(location?.state?.reqId, 1);
      setSearchResume("");
    }
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateWithOfferEntriesData(location?.state?.reqId, 1, searchResume, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateWithOfferEntriesData(location?.state?.reqId, 1, searchResume, access);
    }
  }, [candidateWithOfferEntries, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <div>
              <h3 className="nk-block-title page-title">
                Candidates extended with offers
              </h3>
              <br />
              <h4>
                {location?.state?.JobTitle}
              </h4>
            </div>
            {isCandidateWithOfferLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div >

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search by offer date (e.g. 2024-11-04), candidate name, email, mobile (e.g. 9xxxxxxxxx), offer status, applied for negotiation"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Offer Date</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Offer Status</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Applied for Negotiation</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>
                    </div>

                    {rawCandidateWithOfferDetails.length !== 0 ? (
                      rawCandidateWithOfferDetails.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.date
                                  ? moment(data?.date).format(
                                    "YYYY-MM-DD"
                                  )
                                  : "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("candidate_status") ? (
                                  <NavLink
                                    to={"/candidatestatus"}
                                    state={{
                                      editpost: data,
                                      req_id: location.state?.reqId,
                                      status: true,
                                    }}
                                    id="button-button-candidatestatus"
                                  >
                                    {formatName(data?.candidate_name)}
                                  </NavLink>
                                ) : (
                                  <>{formatName(data?.candidate_name)}</>
                                )}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {data?.offer_acceptance !== null ? (
                                <>
                                  {data?.offer_acceptance === 1 ? (
                                    <span className="badge bg-success">Accepted</span>
                                  ) : data?.offer_acceptance === 0 ? (
                                    <span className="badge bg-danger">Rejected</span>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {data?.applied_negotiation !== null ? (
                                <>
                                  {data?.applied_negotiation ? (
                                    <span className="badge bg-success">Yes</span>
                                  ) : !data?.applied_negotiation ? (
                                    <span className="badge bg-danger">No</span>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {data?.resume ? (
                                <a
                                  className="btn btn-primary"
                                  id="button-button-viewresume"
                                  href={data?.resume}
                                  rel="noreferrer"
                                >
                                  <em
                                    className="icon ni ni-file-docs"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isCandidateWithOfferData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">
                              Good job! You are all caught up.
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidateWithOfferPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidateWithOfferPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleCandidateWithOfferPageClick(
                        location?.state?.reqId,
                        data,
                        searchResume
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateWithOfferCount === null
                          ? 0
                          : candidateWithOfferCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateWithOfferEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateAcceptanceForReq;
