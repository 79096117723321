import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";

const JobLocation = () => {
  const [searchTypeMaster, setSearchTypeMaster] = useState("");
  console.log(searchTypeMaster);
  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();

  const typeMaster =
    location.state?.status === "bgv" ? "bgv_agency" : "job_location";

  const {
    rawJobLocationTypeMaster,
    getJobLocationTypeMaster,
    jobLocationTypeMasterEntries,
    jobLocationTypeMasterPageCount,
    setJobLocationTypeMasterEntries,
    jobLocationTypeMasterCount,
    handleJobLocationTypeMasterClick,
    isJobLocationTypeMasterData,
    isJobLocationTypeMasterLoading,
  } = useContext(MasterTableContext);

  const handleEntries = (event) => {
    setJobLocationTypeMasterEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchTypeMaster(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    console.log(method);
    console.log(searchTypeMaster);
    if (method === "search") {
      getJobLocationTypeMaster(1, searchTypeMaster, typeMaster);
    } else {
      getJobLocationTypeMaster(1, "", typeMaster);
      setSearchTypeMaster("");
    }
  };

  useEffect(() => {
    getJobLocationTypeMaster(1, "", typeMaster);
  }, [jobLocationTypeMasterEntries, location]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              {location.state?.status === "bgv"
                ? "BGV Agencies"
                : "Job Location"}
            </h3>
            {isJobLocationTypeMasterLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {[
            "bgv_agency_create",
            "job_location_create",
          ].some((privilege) => privileges.includes(privilege)) ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createjoblocation"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-createjoblocation"
                state={{ status: location.state?.status }}
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createjoblocation"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-createjoblocation"
                state={{ status: location.state?.status }}
              >
                <em className="icon ni ni-plus"></em>
                <span>
                  Create{" "}
                  {location.state?.status === "bgv"
                    ? "BGV Agency"
                    : "Job Location"}{" "}
                </span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchTypeMaster}
                      className="form-control"
                      placeholder={
                        location.state?.status === "bgv"
                          ? "Search by bgv agency, value and description"
                          : "Search by job location, value and description"
                      }
                      onChange={onChangeHandler}
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>
                          {" "}
                          {location.state?.status === "bgv"
                            ? "BGV Agency"
                            : "Job Location"}
                        </span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Value</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Description</span>
                      </div>

                      {[
                        "job_location_update",
                        "bgv_agency_update",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                    {rawJobLocationTypeMaster.length !== 0 ? (
                      rawJobLocationTypeMaster.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{post?.name || "-"}</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">{post?.value || "-"}</span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.description || "-"}
                            </span>
                          </div>
                          {[
                            "job_location_update",
                            "bgv_agency_update",
                          ].some((privilege) => privileges.includes(privilege)) ? (<div className="nk-tb-col">
                            <NavLink
                              to={"/editjoblocation"}
                              className="btn btn-sm btn-primary m-1"
                              id="button-button-editjoblocation"
                              state={{
                                editpost: post,
                                status: location.state?.status,
                              }}
                            >
                              <em className="icon ni ni-edit"></em>
                            </NavLink>
                          </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isJobLocationTypeMasterData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={jobLocationTypeMasterPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={jobLocationTypeMasterPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleJobLocationTypeMasterClick(
                        data,
                        searchTypeMaster,
                        typeMaster
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {jobLocationTypeMasterCount === null
                          ? 0
                          : jobLocationTypeMasterCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={jobLocationTypeMasterEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobLocation;
