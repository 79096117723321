// export const Formdata = {
//   "tabs": [
//     {
//       "id": 1,
//       "name": "Tab 1",
//       "parent_id": 0,
//       "sequence": 1,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 2,
//           "name": "Tab 1.1",
//           "parent_id": 1,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 1,
//             "form_path" : "Stepper/AssignAssignment/AssignAssignment",
//             "form_name" : "Assign Assignment"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 3,
//           "name": "Tab 1.2",
//           "parent_id": 1,
//           "sequence": 2,
//           "depends_on": [2],
//           "form": {
//             "id" : 2,
//             "form_path" : "Stepper/AssignmentReview/AssignmentReview",
//             "form_name" : "Assignment Review"
//           },
//           "WF": 1,
//         "tabs": [],
//         },
//       ]
//     },
//     {
//       "id": 4,
//       "name": "Tab 2",
//       "parent_id": 0,
//       "sequence": 2,
//       "depends_on": [2,3],
//       "form": {
//         "id" : 3,
//         "form_path" : "Stepper/MettleTest/MettleTest",
//         "form_name" : "Test"
//       },
//       "WF": 1,
//         "tabs": [],
//     },
//     {
//       "id": 5,
//       "name": "Tab 3",
//       "parent_id": 0,
//       "sequence": 3,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 6,
//           "name": "Tab 3.1",
//           "parent_id": 5,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 4,
//             "form_path" : "Stepper/MettleTestReview/MettleTestReview",
//             "form_name" : "Test Review"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 7,
//           "name": "Tab 3.2",
//           "parent_id": 5,
//           "sequence": 2,
//           "depends_on": [2,6],
//           "form": null,
//           "WF": 1,
//           "tabs": [
//             {
//               "id": 8,
//               "name": "Tab 3.2.1",
//               "parent_id": 7,
//               "sequence": 1,
//               "depends_on": [7],
//               "form": {
//                 "id" : 1,
//                 "form_path" : "Stepper/SendOffer/SendOffer",
//                 "form_name" : "Send Offer"
//               },
//               "WF": 1,
//                "tabs": [],
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "sequence": [[0, 0, 0], [0, 1], [1, 0], [2, 0], [2, 1, 0], [3]]
// };

export const candidateFormWorkflow = {
  "candidate_job_id": 70,
  "total_progress": 30,
  "tabs": [
    {
      "id": 1182,
      "name": "Candidate Details",
      "parent_id": 0,
      "sequence": 1,
      "depends_on": [
        0
      ],
      "candidate_accept_reject": 1,
      "form": null,
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 1183,
          "name": "Personal Details",
          "parent_id": 1182,
          "sequence": 2,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": {
            "id": 17,
            "form_name": "Personal Details Retrieve",
            "form_path": "Stepper/CandidateDetails/PersonalDetails"
          },
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 1184,
          "name": "Educational Details",
          "parent_id": 1182,
          "sequence": 3,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": {
            "id": 18,
            "form_name": "Educational Details Retrieve",
            "form_path": "Stepper/CandidateDetails/EducationalDetails"
          },
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 1185,
          "name": "Professional Details",
          "parent_id": 1182,
          "sequence": 4,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": {
            "id": 19,
            "form_name": "Professional Details Retrieve",
            "form_path": "Stepper/CandidateDetails/ProfessionalDetails"
          },
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 1186,
      "name": "Interview Details",
      "parent_id": 0,
      "sequence": 6,
      "depends_on": [
        0
      ],
      "candidate_accept_reject": 1,
      "form": null,
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 1187,
          "name": "Telephonic Interview",
          "parent_id": 1186,
          "sequence": 7,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1188,
              "name": "Interview",
              "parent_id": 1187,
              "sequence": 8,
              "depends_on": [
                0
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 13,
                "form_name": "Telephonic Interview",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicRound"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1189,
              "name": "Review",
              "parent_id": 1187,
              "sequence": 9,
              "depends_on": [
                1188
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 24,
                "form_name": "Telephonic Interview Review",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicInterviewReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 1190,
          "name": "Technical Interview",
          "parent_id": 1186,
          "sequence": 10,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1191,
              "name": "Interview",
              "parent_id": 1190,
              "sequence": 11,
              "depends_on": [
                0
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 11,
                "form_name": "Technical Interview",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1192,
              "name": "Review",
              "parent_id": 1190,
              "sequence": 12,
              "depends_on": [
                0
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 22,
                "form_name": "Technical Interview Review",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1366,
              "name": "Interview 2",
              "parent_id": 1190,
              "sequence": 13,
              "depends_on": [
                0
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 11,
                "form_name": "Technical Interview",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": null,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1367,
              "name": "Review 2",
              "parent_id": 1190,
              "sequence": 14,
              "depends_on": [
                0
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 22,
                "form_name": "Technical Interview Review",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": null,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 1193,
      "name": "Assessment",
      "parent_id": 0,
      "sequence": 15,
      "depends_on": [
        0
      ],
      "candidate_accept_reject": 1,
      "form": null,
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 1194,
          "name": "Assignment",
          "parent_id": 1193,
          "sequence": 16,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1195,
              "name": "Assign",
              "parent_id": 1194,
              "sequence": 17,
              "depends_on": [
                1366
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 2,
                "form_name": "Assign Assignment",
                "form_path": "Stepper/AssignAssignment/AssignAssignment"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1196,
              "name": "Upload",
              "parent_id": 1194,
              "sequence": 18,
              "depends_on": [
                1195
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 32,
                "form_name": "Assignment Upload",
                "form_path": "Stepper/AssignAssignment/AssignmentUpload"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1197,
              "name": "Review",
              "parent_id": 1194,
              "sequence": 19,
              "depends_on": [
                1196
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 1,
                "form_name": "Assignment Review",
                "form_path": "Stepper/AssignmentReview/AssignmentReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 1198,
          "name": "Psychometric Test",
          "parent_id": 1193,
          "sequence": 20,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1199,
              "name": "Assign",
              "parent_id": 1198,
              "sequence": 21,
              "depends_on": [
                1197
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 4,
                "form_name": "Test",
                "form_path": "Stepper/MettleTest/MettleTest"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1200,
              "name": "Review",
              "parent_id": 1198,
              "sequence": 22,
              "depends_on": [
                1199
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 5,
                "form_name": "Test Review",
                "form_path": "Stepper/MettleTestReview/MettleTestReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 1201,
          "name": "Smart Hire Form",
          "parent_id": 1193,
          "sequence": 23,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1202,
              "name": "Assign",
              "parent_id": 1201,
              "sequence": 24,
              "depends_on": [
                1200
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 14,
                "form_name": "Smart Hire Form",
                "form_path": "Stepper/SmartHireForm/SmartHireForm"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1203,
              "name": "Review",
              "parent_id": 1201,
              "sequence": 25,
              "depends_on": [
                1202
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 23,
                "form_name": "Smart Hire Form Review",
                "form_path": "Stepper/SmartHireForm/SmartHireFormReview"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 1204,
      "name": "HRD Approval",
      "parent_id": 0,
      "sequence": 26,
      "depends_on": [
        1203
      ],
      "candidate_accept_reject": 1,
      "form": {
        "id": 15,
        "form_name": "HRD Approval",
        "form_path": "Stepper/HRDApproval/HRDApproval"
      },
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 1205,
      "name": "MD Approval",
      "parent_id": 0,
      "sequence": 27,
      "depends_on": [
        1204
      ],
      "candidate_accept_reject": 1,
      "form": {
        "id": 3,
        "form_name": "MD Approval",
        "form_path": "Stepper/MDApproval/MDApproval"
      },
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 1206,
      "name": "Know Your Candidate",
      "parent_id": 0,
      "sequence": 28,
      "depends_on": [
        1205
      ],
      "candidate_accept_reject": 1,
      "form": {
        "id": 16,
        "form_name": "KYC",
        "form_path": "Stepper/KYC/KYC"
      },
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 1207,
      "name": "Offer",
      "parent_id": 0,
      "sequence": 29,
      "depends_on": [
        1206
      ],
      "candidate_accept_reject": 1,
      "form": {
        "id": 6,
        "form_name": "Send Offer",
        "form_path": "Stepper/SendOffer/SendOffer"
      },
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 1208,
      "name": "Background Verification",
      "parent_id": 0,
      "sequence": 30,
      "depends_on": [
        0
      ],
      "candidate_accept_reject": 1,
      "form": null,
      "WF": 2,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 1209,
          "name": "Background Verification 1",
          "parent_id": 1208,
          "sequence": 31,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1210,
              "name": "Upload",
              "parent_id": 1209,
              "sequence": 32,
              "depends_on": [
                1207
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 26,
                "form_name": "Background Verification one",
                "form_path": "Stepper/BackgroundVerification/BGV1"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1211,
              "name": "Review",
              "parent_id": 1209,
              "sequence": 33,
              "depends_on": [
                1210
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 29,
                "form_name": "Background Verification one Review",
                "form_path": "Stepper/BackgroundVerification/BGV1Review"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 1212,
          "name": "Background Verification 2",
          "parent_id": 1208,
          "sequence": 34,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1213,
              "name": "Upload",
              "parent_id": 1212,
              "sequence": 35,
              "depends_on": [
                1207
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 27,
                "form_name": "Background Verification two",
                "form_path": "Stepper/BackgroundVerification/BGV2"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1214,
              "name": "Review",
              "parent_id": 1212,
              "sequence": 36,
              "depends_on": [
                1213
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 30,
                "form_name": "Background Verification two Review",
                "form_path": "Stepper/BackgroundVerification/BGV2Review"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 1215,
          "name": "Background Verification 3",
          "parent_id": 1208,
          "sequence": 37,
          "depends_on": [
            0
          ],
          "candidate_accept_reject": 1,
          "form": null,
          "WF": 2,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 1216,
              "name": "Upload",
              "parent_id": 1215,
              "sequence": 38,
              "depends_on": [
                1207
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 28,
                "form_name": "Background Verification three",
                "form_path": "Stepper/BackgroundVerification/BGV3"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 1217,
              "name": "Review",
              "parent_id": 1215,
              "sequence": 39,
              "depends_on": [
                1216
              ],
              "candidate_accept_reject": 1,
              "form": {
                "id": 31,
                "form_name": "Background Verification three Review",
                "form_path": "Stepper/BackgroundVerification/BGV3Review"
              },
              "WF": 2,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    }
  ],
  "sequence": [
    [
      0,
      0
    ],
    [
      0,
      1
    ],
    [
      0,
      2
    ],
    [
      1,
      0,
      0
    ],
    [
      1,
      0,
      1
    ],
    [
      1,
      1,
      0
    ],
    [
      1,
      1,
      1
    ],
    [
      1,
      1,
      2
    ],
    [
      1,
      1,
      3
    ],
    [
      2,
      0,
      0
    ],
    [
      2,
      0,
      1
    ],
    [
      2,
      0,
      2
    ],
    [
      2,
      1,
      0
    ],
    [
      2,
      1,
      1
    ],
    [
      2,
      2,
      0
    ],
    [
      2,
      2,
      1
    ],
    [
      3
    ],
    [
      4
    ],
    [
      5
    ],
    [
      6
    ],
    [
      7,
      0,
      0
    ],
    [
      7,
      0,
      1
    ],
    [
      7,
      1,
      0
    ],
    [
      7,
      1,
      1
    ],
    [
      7,
      2,
      0
    ],
    [
      7,
      2,
      1
    ]
  ]
}

export const session = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 0,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 3,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const configurablePrivilege = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const TamOptions = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "FSC Careers",
    value: "FSC_careers",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
];

export const RE2Options = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Updaaz",
    value: "Updaaz",
  },
  {
    label: "Hirist",
    value: "Hirist",
  },
];

export const TypemasterData = {
  data: [
    {
      category: {
        category_name: "Department",
      },
      name: "Software S/W",
      value: "1",
    },
    {
      category: {
        category_name: "Department",
      },
      name: "Hardware",
      value: "2",
    },
  ],
};

export const Typemaster = {
  data: {
    Department: [
      {
        name: "Software S/W",
        value: "1",
      },
      {
        name: "Hardware",
        value: "2",
      },
    ],
    Job: [
      {
        name: "Engineer",
        value: "1",
      },
      {
        name: "QA",
        value: "2",
      },
    ],
  },
};

export const RCQuestions = [
  {
    label: "Overqualified",
  },
  {
    label: "Overexperienced",
  },
  {
    label: "High potential but for another position",
  },
  {
    label: "Insufficient experience",
  },
  {
    label: "Irrelevant experience",
  },
  {
    label: "Lack of required skills",
  },
  {
    label: "Banned",
  },
];

export const TI = [
  {
    interview_id: 1,
    questions_data: [
      { question: "TI 4", score: "1", comments: "C1" },
      { question: "TI 3", score: "2", comments: "C2" },
      { question: "TI 2", score: "3", comments: "C3" },
    ],
    categories: [
      { category: "Communication", manualMarks: 2 },
      { category: "Coding", manualMarks: 4 },
    ],
    total_score: 6,
    feedback: "Feedback",
    candidate_status: "Draft",
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const telephonicData = {
  "interview_details": {
    "id": 63,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      }
    ],
    "interview_type": "HR Telephonic",
    "comments": null,
    "total_score": 8.0,
    "candidate_accept_reject": null,
    "interview_action": 1,
    "requisition": {
      "id": 25,
      "required_experience": 4.0,
      "min_salary": 3,
      "max_salary": 4
    },
    "interview_datetime": "2024-09-18T00:00:00+05:30",
    "google_meet_link": "https://meet.google.com/jqs-xdma-kti"
  },
  "interview_question_score": [],
  "interview_score": {
    "id": 33,
    "category": "HR Telephonic",
    "score": 8.0,
    "comments": null
  }
};

export const KYCDATA = [
  {
    id: 1,
    label: "AADHAR CARD",
    document: "done",
  },
  {
    id: 2,
    label: "PAN CARD",
    document: null,
  },
  {
    id: 3,
    label: "Appointment Letter/Offer Letter",
    document: null,
  },
  {
    id: 4,
    label: "Appraisal/Increment Letter",
    document: "done",
  },
  {
    id: 5,
    label: "Last three months salary slip",
    document: null,
  },
  {
    id: 6,
    label: "Last six months salary account bank statement",
    document: "done",
  },
  {
    id: 7,
    label: "Recent passport size photograph",
    document: "done",
  },
];

export const assignment = {
  "id": 16,
  "assignment_conducted_user": [
    {
      "id": 7,
      "first_name": "Nyssa",
      "last_name": "Rodgers"
    },
    {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    {
      "id": 40,
      "first_name": "SuperAdmin",
      "last_name": "Test"
    }
  ],
  "choose_assignment": {
    "id": 8,
    "job_opening": {
      "id": 653,
      "job_title": "Sr. HR Head"
    },
    "assignment_title": "English",
    "assignment_document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/JobAssignment_document/Resume_3_yKTb2wG.pdf"
  },
  "upload_assignment": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/Assignment_upload__assignment/resume1_pGn7q4O.pdf",
  "feedback": null,
  "manager_feedback": null,
  "comments": null,
  "candidate_feedback": null,
  "selection_status": null,
  "total_score": null,
  "start_date": "2024-10-22",
  "end_date": "2024-10-25",
  "extended_end_date": null,
  "extend_request": false,
  "assignment_review_by": [
    {
      "user": {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      },
      "comments": "Good",
      "feedback": "Excellent",
      "total_score": 4,
      "selection_status": 2
    },
    {
      "user": {
        "id": 40,
        "first_name": "SuperAdmin",
        "last_name": "Test"
      },
      "comments": "Very good",
      "feedback": "Good to go",
      "total_score": 4,
      "selection_status": 1
    }
  ]
}

export const technicalDetails = {
  "interview_details": {
    "id": 44,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      }
    ],
    "interview_type": "Technical_1",
    "comments": "approved",
    "total_score": 3.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "requisition": {
      "id": 8,
      "required_experience": 7.0,
      "min_salary": 10,
      "max_salary": 15
    },
    "interview_datetime": "2024-10-10T15:55:00+05:30",
    "google_meet_link": "https://meet.google.com/azb-cbmv-ghv",
    "interview_feedback": [
      {
        "id": 15,
        "interviewer": {
          "id": 40,
          "first_name": "Nyssa",
          "last_name": "Rodgers"
        },
        "total_score": 3.0,
        "comments": "Cant go ahead",
        "candidate_accept_reject": 0,
        "categories_data": [
          {
            "id": 32,
            "category": "Pre-screening-test",
            "score": 3.0,
            "comments": null
          }
        ],
        "questions_data": [
          {
            "id": 221,
            "question": "What are the key features you look for in GPS tracking software?",
            "answer": null,
            "question_status": null,
            "score": 3.0,
            "comments": "Cant go ahead"
          }
        ]
      }
    ]
  }
}

export const candidateExam = [
  {
    "id": 10,
    "test_name": "phycometric test",
    "test_link": "https://google.com",
    "test_conducted_user": {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    "comments": "no",
    "selection_status": 0,
    "test_done": null,
    "upload_test": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/CandidateExam_upload_test/sample-crack-of-dawn-resume.pdf",
    "score": null
  }
]

export const SHFData = [
  {
    "interview_details": {
      "id": 15,
      "interview_mode": "Online",
      "interviewer_name": [],
      "interview_type": "SHF",
      "comments": "can go ahead",
      "total_score": 4.0,
      "candidate_accept_reject": 0,
      "interview_action": null,
      "requisition": {
        "id": 7,
        "required_experience": 2.0,
        "min_salary": 3,
        "max_salary": 5
      },
      "interview_datetime": null,
      "google_meet_link": null
    },
    "interview_question_score": [
      {
        "id": 413,
        "question": "Name",
        "answer": "Pravin Chaudhary",
        "question_status": true,
        "score": 0,
        "comments": null
      },
      {
        "id": 414,
        "question": "Email",
        "answer": "pravinchaudhary@gmail.com",
        "question_status": true,
        "score": 0,
        "comments": null
      },
      {
        "id": 415,
        "question": "How will you define yourself as a sheep or wolf ?",
        "answer": "Sheep",
        "question_status": true,
        "score": 4,
        "comments": null
      },
      {
        "id": 416,
        "question": "What relevant experience do you have that aligns with this job?",
        "answer": "Experience and team leading skills",
        "question_status": true,
        "score": 1,
        "comments": null
      }
    ]
  }
]

export const candidateAdditionalData = {
  "id": 54,
  "job_opening_id": 241,
  "user": {
    "id": 68,
    "first_name": "Lina",
    "last_name": "Deshpande"
  },
  "shortlisted_user_id": null,
  "candidate_resume_id": 94,
  "notice_period": null,
  "current_previous_company": null,
  "expected_salary": null,
  "experience": null,
  "is_draft": false,
  "is_shortlisted": true,
  "md_score": 5.0,
  "md_approval": 1,
  "md_comments": "no",
  "md_feedback": "Good to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to goGood to go",
  "hrd_approval": null,
  "hrd_comments": "Yes good to go",
  "hrd_feedback": "Good",
  "hrd_score": 5.0,
  "view_resume_count": null,
  "activity_status": 2,
  "created_at": "2024-09-12T15:58:00.873675+05:30",
  "updated_at": "2024-09-16T16:06:59.683373+05:30",
  "HR_Telephonic": {
    "id": 63,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      }
    ],
    "interview_type": "HR Telephonic",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": "2024-09-18T00:00:00+05:30",
    "comments": null,
    "total_score": 8.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "google_meet_link": "https://meet.google.com/jqs-xdma-kti"
  },
  "SHF": {
    "id": 64,
    "interview_mode": "Online",
    "interviewer_name": [],
    "interview_type": "SHF",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": null,
    "comments": "no ",
    "total_score": 0.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "google_meet_link": null
  },
  "Technical": {
    "id": 66,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      }
    ],
    "interview_type": "Technical_2",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": null,
    "comments": null,
    "total_score": null,
    "candidate_accept_reject": null,
    "interview_action": null,
    "google_meet_link": "https://meet.google.com/kyp-uecc-yuc"
  },
  "assignment_data": {
    "id": 13,
    "assignment_conducted_user": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      },
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      },
      {
        "id": 10,
        "first_name": "Zahir",
        "last_name": "Calhoun"
      }
    ],
    "upload_assignment": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/Assignment_upload__assignment/sample-crack-of-dawn-resume.pdf",
    "feedback": null,
    "manager_feedback": null,
    "comments": "good to go",
    "candidate_feedback": null,
    "selection_status": 1,
    "total_score": null,
    "start_date": "2024-09-12",
    "end_date": "2024-09-14",
    "extended_end_date": null,
    "extend_request": false
  },
  "exam_data": {
    "id": 10,
    "test_name": "phycometric test",
    "test_link": "https://google.com",
    "test_conducted_user": {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    "comments": "no",
    "selection_status": 1,
    "test_done": null,
    "upload_test": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/CandidateExam_upload_test/sample-crack-of-dawn-resume.pdf",
    "score": null
  },
  "kyc_data": {
    "id": 19,
    "requisition": 25,
    "candidate": 54,
    "documents": [
      {
        "id": 31,
        "document_name": "photo",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-crack-of-dawn-resume.pdf"
      },
      {
        "id": 32,
        "document_name": "six_months_salary_slip",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume.pdf"
      },
      {
        "id": 33,
        "document_name": "three_months_salary_slip",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/report.png"
      },
      {
        "id": 34,
        "document_name": "increment_letter",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_ZVdv7vK.pdf"
      },
      {
        "id": 35,
        "document_name": "offer_letter",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_UQX4Bdy.pdf"
      },
      {
        "id": 36,
        "document_name": "pan_card",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_wFWQ8QG.pdf"
      },
      {
        "id": 37,
        "document_name": "aadhar_card",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-corporate-resume_WPh3ni4.pdf"
      }
    ]
  }
}

export const requisitionByUserData = [
  {
    "id": 435,
    "job_opening": {
      "id": 328,
      "job_title": "Exec. Paid Media Specialist",
      "department": {
        "id": 6,
        "department_name": "Digital Marketing Dept.",
        "department_display_name": "Digital Marketing Dept.",
        "department_incharge": null
      },
      "team": {
        "id": 9,
        "team_name": "Social Media Team_DM",
        "team_display_name": "Social Media Team_DM",
        "team_incharge": null
      },
      "position": {
        "id": 236,
        "position_name": "Paid Media Specialist",
        "position_display_name": "Paid Media Specialist"
      },
      "seniority": {
        "id": 3,
        "seniority_name": "Exec.",
        "seniority_display_name": "Exec.",
        "sequence": 2
      },
      "job_description": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "job_requirement": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "workflow": {
        "id": 69,
        "workflow_name": "Workflow - 3",
        "workflow_category": "Candidate"
      },
      "experience": 5.0,
      "location": "Kolkata",
      "vacancy": null,
      "seo_title": "Exec. Paid Media Specialist",
      "seo_keywords": "Exec. Paid Media Specialist",
      "seo_meta_key": "Exec. Paid Media Specialist",
      "url_required": "Exec. Paid Media Specialist",
      "activity_status": 0
    },
    "created_at": "2024-08-10T16:28:08.659540+05:30",
    "no_of_vacancy": 1,
    "job_type": "Part-time",
    "job_location": "Pune",
    "required_experience": 1.0,
    "min_salary": 2,
    "max_salary": 3,
    "deadline": null,
    "status": 0,
    "priority": "High",
    "comments": "Comments",
    "created_since": 1,
    "hrd_approved": 1,
    "md_approved": 2,
    "total_resume": 4,
    "candidate_in_process": { "received": 1, "processed": 3 },
    "shortlisted_candidate": { "received": 2, "processed": 3 },
    "sme_shortlisted_candidate": { "received": 3, "processed": 2 },
    "telephonic_candidates": { "received": 3, "processed": 1 },
    "technical_candidates": { "received": 4, "processed": 1 },
    "hr_candidates": { "received": 2, "processed": 0 },
    "assignment_candidates": { "received": 5, "processed": 0 },
    "test_candidates": { "received": 6, "processed": 0 },
    "shf_candidates": { "received": 7, "processed": 1 },
    "bgv_candidates": { "received": 8, "processed": 0 }
  }
]

export const tamInterviewSlots = [
  {
    "interviewer": {
      "id": 1,
      "first_name": "Test",
      "last_name": "User",
      "slots": [
        {
          "id": 21,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-27T01:00:00+05:30",
          "slot_end_time": "2024-08-27T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": {
            "id": 697,
            "first_name": "Aaradhya",
            "last_name": "Jaiswal"
          }
        },
        {
          "id": 22,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-07T01:00:00+05:30",
          "slot_end_time": "2024-08-07T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": null
        }
      ],
    }
  }
]

export const rawNotificationMapper = [
  {
    "id": 25,
    "stage": "Awaiting for MD Approval",
    "notification_message": "Requisition awaiting for MD/HRD approval",
    "specific_user": true,
    "is_active": false,
    "user": [
      {
        "id": 4,
        "first_name": "hrd",
        "last_name": "tw"
      },
      {
        "id": 5,
        "first_name": "md",
        "last_name": "tw"
      }
    ],
    "role": [
      {
        "id": 17,
        "role_name": "Superadmin",
        "role_value": "superadmin",
        "level": 1,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ]
  },
  {
    "id": 24,
    "stage": "Awaiting for HRD Approval",
    "notification_message": "Requisition awaiting for HRD/MD approval",
    "specific_user": false,
    "is_active": true,
    "user": [],
    "role": [
      {
        "id": 19,
        "role_name": "HR Director",
        "role_value": "HRD",
        "level": 3,
        "landing_page": "HR-D Approval",
        "landing_page_path": "/hrd-approval"
      },
      {
        "id": 28,
        "role_name": "Candidate",
        "role_value": "candidate",
        "level": 13,
        "landing_page": null,
        "landing_page_path": null
      }
    ]
  }
]

export const rawWhatsAppNotification = [
  {
    "id": 2,
    "stage": "Requisition Raised",
    "template_id": "HXe12cfc70bfefafb41e9a9afd082cad07",
    "template_message": "Hello {{1}},A new requisition for the {{2}} position has been raised and is pending your approval. Please review the requisition at your earliest convenience.Best regards,{{3}}",
    "specific_user": true,
    "is_active": false,
    "user": [
      {
        "id": 4,
        "first_name": "hrd",
        "last_name": "tw"
      },
      {
        "id": 5,
        "first_name": "md",
        "last_name": "tw"
      }
    ],
    "role": [
      {
        "id": 17,
        "role_name": "Superadmin",
        "role_value": "superadmin",
        "level": 1,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ]
  },
  {
    "id": 1,
    "stage": "Requisition Approved",
    "template_id": "HX962428435e43f911713b001ae62a545a",
    "template_message": "Hello {{1}},The requisition for the {{2}} position has been approved and is now pending assignment to RE1-2-3. Please proceed with the assignment process.Best regards,{{3}}",
    "specific_user": false,
    "is_active": true,
    "user": [],
    "role": [
      {
        "id": 18,
        "role_name": "MD",
        "role_value": "MD",
        "level": 2,
        "landing_page": "MD Approval",
        "landing_page_path": "/md-approval"
      },
      {
        "id": 19,
        "role_name": "HR Director",
        "role_value": "HRD",
        "level": 3,
        "landing_page": "HR-D Approval",
        "landing_page_path": "/hrd-approval"
      },
      {
        "id": 21,
        "role_name": "TAM",
        "role_value": "TAM",
        "level": 5,
        "landing_page": "Assess Requistions",
        "landing_page_path": "/assess-requisitions"
      }
    ]
  }
]

export const rawEmailNotification = [
  {
    "id": 4,
    "email_type": "Requisition Raised",
    "is_active": false,
    "subject": "New Requisition Raised",
    "message": " Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition. Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.",
    "bcc_specific_user": true,
    "bcc_user": [
      {
        "id": 3,
        "first_name": "tam",
        "last_name": "tw"
      }
    ],
    "bcc_role": [
      {
        "id": 29,
        "role_name": "Career",
        "role_value": "career",
        "level": 14,
        "landing_page": null,
        "landing_page_path": null
      }
    ],
    "cc_specific_user": true,
    "cc_user": [
      {
        "id": 6,
        "first_name": "departmental manager",
        "last_name": "tw"
      }
    ],
    "cc_role": [
      {
        "id": 20,
        "role_name": "Departmental Manager",
        "role_value": "departmental_manager",
        "level": 4,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ],
    "to_specific_user": true,
    "to_user": [
      {
        "id": 2,
        "first_name": "superadmin",
        "last_name": "tw"
      },
      {
        "id": 3,
        "first_name": "tam",
        "last_name": "tw"
      }
    ],
    "to_role": [
      {
        "id": 28,
        "role_name": "Candidate",
        "role_value": "candidate",
        "level": 13,
        "landing_page": null,
        "landing_page_path": null
      },
      {
        "id": 29,
        "role_name": "Career",
        "role_value": "career",
        "level": 14,
        "landing_page": null,
        "landing_page_path": null
      }
    ]
  },
  {
    "id": 3,
    "email_type": "Candidate Shortlisted",
    "is_active": true,
    "subject": "Congratulations! You’ve Been Shortlisted – Next Steps to Move Forward",
    "message": "Dear {candidate_name},\r\n\r\nCongratulations! We are pleased to inform you that you have been shortlisted for the next stage of our recruitment process at Fluidscapes.\r\n\r\nTo proceed, we kindly request you to log in to our career portal and complete your personal, educational, and professional details. Your credentials for logging in are provided below:\r\n\r\nEmail: {email}\r\nPassword: {password}\r\nCareer Portal: career-page-test.techwalnut.co.in\r\n\r\n",
    "bcc_specific_user": false,
    "bcc_user": [],
    "bcc_role": [],
    "cc_specific_user": false,
    "cc_user": [],
    "cc_role": [],
    "to_specific_user": false,
    "to_user": [],
    "to_role": []
  }
]

export const notifications = [
  {
    "id": 973,
    "title": "Assignment Submitted",
    "message": "FSC-R34 Requisition: Candidate Arhaan Sen has submitted the assignment.",
    "is_read": false,
    "created_at": "2024-10-02T17:28:28.317359+05:30",
    "redirection_page": [
      "Interview Scheduling"
    ],
    "redirection_page_path": [
      {
        'path': "/trackrequisitions",
        'privilege': 'track_requisition_retrieve'
      }
    ]
  },
  {
    "id": 857,
    "title": "Technical Interview Scheduled",
    "message": "FSC-R34 Requisition: Technical Interview scheduled for candidate Aarush Mathur.",
    "is_read": false,
    "created_at": "2024-10-01T23:38:08.804920+05:30",
    "redirection_page": [
      "Track Requisitions",
      "Schedule Interview"
    ],
    "redirection_page_path": [
      {
        'path': "/trackrequisitions",
        'privilege': 'track_requisition_retrieveee'
      },
      {
        'path': "/interviewscheduling",
        'privilege': 'interview_scheduling_requisition_retrieveee'
      }
    ]
  },
  {
    "id": 487,
    "title": "Resume Shortlisted by SME",
    "message": "FSC-R21-New Resume shortlisted by SME. Awaiting for further process",
    "is_read": false,
    "created_at": "2024-09-23T19:58:48.109248+05:30",
    "redirection_page": null,
    "redirection_page_path": null
  }
]

export const technicalInterviewQuestion = [
  {
    "id": 249,
    "interview_type": [
      "Technical"
    ],
    "category": [
      "Communication",
    ],
    "question": "What is effective communication",
    "answer": "I begin by closely collaborating with senior leadership to understand the company’s vision and long-term objectives. Based on this, I ensure HR initiatives support those goals. For example, if a company is aiming to scale operations, I focus on building a robust talent acquisition strategy and strengthening leadership development programs to support this growth.",
    "job_opening": [
      {
        "id": 655,
        "job_title": "Exec. HR Head",
        "department": 16,
        "team": 49,
        "position": 546
      }
    ]
  },
  {
    "id": 250,
    "interview_type": [
      "Technical"
    ],
    "category": [
      "Pre-screening-test"
    ],
    "question": "CWhat is HTML",
    "answer": "In my previous role, we faced high employee turnover, which impacted productivity. I led a strategy to improve employee engagement through regular feedback loops, career development plans, and flexible work arrangements. We implemented quarterly engagement surveys, introduced mentorship programs, and created individualized growth paths for employees. As a result, our turnover rate dropped by 20%, and employee satisfaction increased significantly.",
    "job_opening": [
      {
        "id": 655,
        "job_title": "Exec. HR Head",
        "department": 16,
        "team": 49,
        "position": 546
      }
    ]
  },
  {
    "id": 255,
    "interview_type": [
      "Technical"
    ],
    "category": [
      "screening-test"
    ],
    "question": "What is HTML",
    "answer": "In my previous role, we faced high employee turnover, which impacted productivity. I led a strategy to improve employee engagement through regular feedback loops, career development plans, and flexible work arrangements. We implemented quarterly engagement surveys, introduced mentorship programs, and created individualized growth paths for employees. As a result, our turnover rate dropped by 20%, and employee satisfaction increased significantly.",
    "job_opening": [
      {
        "id": 655,
        "job_title": "Exec. HR Head",
        "department": 16,
        "team": 49,
        "position": 546
      }
    ]
  }
]