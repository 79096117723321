import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";
import Swal from "sweetalert2";
import axios from "axios";
import { VARIABLES } from "../../Constants";

const NotificationMapper = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchNotificationMapper, setSearchNotificationMapper] = useState("");
  const [submitMessage, setSubmitMessage] = useState(false);

  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  const {
    rawNotificationMapper,
    setRawNotificationMapper,
    getNotificationMapperData,
    notificationMapperEntries,
    notificationMapperPageCount,
    setNotificationMapperEntries,
    notificationMapperCount,
    handleNotificationMapperClick,
    isNotificationMapperData,
    isNotificationMapperLoading,
    setIsNotificationMapperLoading
  } = useContext(NotificationsContext);

  const handleEntries = (event) => {
    setNotificationMapperEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchNotificationMapper(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getNotificationMapperData(1, searchNotificationMapper);
    } else {
      getNotificationMapperData(1);
      setSearchNotificationMapper("");
    }
  };

  const handleStatus = (updatedStatus, index) => {
    console.log(updatedStatus);
    console.log(index);
    setRawNotificationMapper((prevActivityStatus) => {
      const updatedPostings = [...prevActivityStatus];
      updatedPostings[index].is_active = updatedStatus;
      return updatedPostings;
    });

    var user = JSON.stringify({
      is_active: updatedStatus,
      updated_by_id: userID,
    });

    console.log(user);
    const url =
      VARIABLES.url + `/api/notification-mapper/${rawNotificationMapper[index]?.id}/?type=notification_status`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    setIsNotificationMapperLoading(true)
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: updatedStatus ? "Notification is active" : "Notification is inactive",
        });
        setIsNotificationMapperLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsNotificationMapperLoading(false)
        setRawNotificationMapper((prevActivityStatus) => {
          const reversePostings = [...prevActivityStatus];
          reversePostings[index].is_active = !updatedStatus;
          return reversePostings;
        });
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to change notification status",
        });
      });
  };

  const handleStatusAlert = (isChecked, index) => {
    console.log(isChecked)
    Swal.fire({
      text: `Are you sure to ${isChecked ? 'active' : 'deactivate'} this notification?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatus(isChecked, index);
      } else {
      }
    });
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getNotificationMapperData(1, searchNotificationMapper, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getNotificationMapperData(1, searchNotificationMapper, access);
    }
  }, [notificationMapperEntries, isAccess]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      {/* <WelcomeMessage /> */}
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">App Notification</h3>
            {isNotificationMapperLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchNotificationMapper}
                      className="form-control"
                      placeholder="Search by stage, notification message, status (e.g. active / inactive)"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Stage</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Notification Message</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Status</span>
                      </div>

                      {privileges.includes("notification_mapper_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawNotificationMapper.length > 0 ? (
                      rawNotificationMapper.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{post.stage || "-"}</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.notification_message || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch${index}`}
                                checked={post?.is_active === true}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleStatusAlert(isChecked, index);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                id="button-button-status"
                                htmlFor={`customSwitch${index}`}
                              ></label>
                            </div>
                          </div>

                          {privileges.includes("notification_mapper_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editnotificationmapper"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editnotificationmapper"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isNotificationMapperData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={notificationMapperPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={notificationMapperPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleNotificationMapperClick(
                        data,
                        searchNotificationMapper
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {notificationMapperCount === null
                          ? 0
                          : notificationMapperCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={notificationMapperEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationMapper;
