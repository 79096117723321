import React, { useContext, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { JobOpeningContext } from "../../Context/JobOpeningContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const EditSeo = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const { getJobDescriptionData } = useContext(JobOpeningContext);

  const [submitMessage, setSubmitMessage] = useState(null);
  console.log(submitMessage);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();
  console.log(location.state?.editseo);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    console.log(data);
    var seo = JSON.stringify({
      seo_title: data.seo_title,
      seo_keywords: data.seo_keywords,
      seo_meta_key: data.seo_meta_tags,
      url_required: data.url_req,
      updated_by_id: userID,
    });
    const url = VARIABLES.url + `/api/jobopening/${location.state.editseo.id}/`;
    console.log(url);
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: seo,
    };

    console.log(seo);

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        getJobDescriptionData();
        console.log(JSON.stringify(response.data));
        getJobDescriptionData();
        onTATSubmit()
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Seo successfully updated",
        });
        reset();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error.response.data.message,
        });
      });
  };

  const onTATSubmit = () => {
    var user = JSON.stringify({
      requisition_id: location.state?.id,
      stage: "SEO",
      updated_by_id: userID
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    console.log(location.state?.editseo);
    const editedSEO = location.state?.editseo;
    if (editedSEO) {
      var defaultValues = {};
      defaultValues.seo_title = editedSEO.seo_title;
      defaultValues.seo_keywords = editedSEO.seo_keywords;
      defaultValues.seo_meta_tags = editedSEO.seo_meta_key;
      defaultValues.url_req = editedSEO.url_required;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">SEO</h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Job Post </label>
                    <div className="form-control">
                      {location.state?.editseo?.job_title}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-seo_title"
                      placeholder="Enter title"
                      {...register("seo_title", {
                        required: true,
                        // pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.seo_title &&
                      errors.seo_title.type === "required" && (
                        <span className="fs-8 text-danger">
                          Title is required
                        </span>
                      )}
                    {/* {errors.seo_title &&
                      errors.seo_title.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          Title should be an alphabet
                        </span>
                      )} */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Keywords<span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="text-forms-seo_keywords"
                      placeholder="Enter keywords"
                      {...register("seo_keywords", {
                        required: true,
                        // pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.seo_keywords &&
                      errors.seo_keywords.type === "required" && (
                        <span className="fs-8 text-danger">
                          Keywords is required
                        </span>
                      )}
                    {/* {errors.seo_keywords &&
                      errors.seo_keywords.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          Keywords should be an alphabet
                        </span>
                      )} */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Meta Tags<span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="text-forms-seo_meta_key"
                      placeholder="Enter meta tags"
                      {...register("seo_meta_tags", {
                        required: true,
                        // pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.seo_meta_tags &&
                      errors.seo_meta_tags.type === "required" && (
                        <span className="fs-8 text-danger">
                          Meta Tags is required
                        </span>
                      )}
                    {/* {errors.seo_meta_tags &&
                      errors.seo_meta_tags.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          Meta Tags should be an alphabet
                        </span>
                      )} */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Url Required<span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="text-forms-url_required"
                      placeholder="Enter required url"
                      {...register("url_req", {
                        required: true,
                      })}
                    />
                    {errors.url_req && (
                      <span className="fs-8 text-danger">
                        Required url is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {isLoading ? (
                      <button
                        id="loading-button"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editseo"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditSeo;
