import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";

const EducationalDetails = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const location = useLocation();
  const { sequenceList, level, prevLevel, nextLevel } =
    useContext(SequenceContext);

  const {
    getCandidateOtherData,
    candidateOtherDetails,
    isDetailsLoading,
    isDetailsData,
  } = useContext(CandidateEntriesContext);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    }
  }, [location, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Educational Details</h4>
            {isDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="row g-3">
          {!isDetailsLoading && (
            <>
              {candidateOtherDetails.length !== 0 && candidateOtherDetails?.educational_details?.length > 0 ? (
                candidateOtherDetails?.educational_details?.map((edu, index) => (
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="py-4 border-css">
                      <div className="profile-ud-list ">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Board Name</span>
                            <span className="profile-ud-value">
                              {edu?.board_name || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Passing Year</span>
                            <span className="profile-ud-value">
                              {edu?.passing_year || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Percentage</span>
                            <span className="profile-ud-value">
                              {edu?.percentage || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Marksheet</span>
                            <span className="profile-ud-value">
                              {edu?.marksheet ? (
                                <a
                                  target="_blank"
                                  href={edu?.marksheet}
                                  class="caption-text text-break"
                                >
                                  {" "}
                                  View Marksheet{" "}
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Qualification</span>
                            <span className="profile-ud-value">
                              {edu?.education_type.label || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Specialization</span>
                            <span className="profile-ud-value">
                              {edu?.specialization || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Comments</span>
                            <span className="profile-ud-value">
                              {edu?.comments || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-product">
                      <span className="title">No Educational Information found.</span>
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="col-12">
            <div className="form-group">
              {level !== 0 && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary me-3"
                  id="button-button-prevlevel"
                  onClick={() => prevLevel()}
                >
                  Prev
                </button>
              )}
              {sequenceList.length - 2 > level ? (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                  onClick={() => nextLevel()}
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationalDetails;
