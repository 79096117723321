import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";

const PersonalDetails = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const location = useLocation();
  const { sequenceList, level, prevLevel, nextLevel } =
    useContext(SequenceContext);

  const {
    getCandidateOtherData,
    candidateOtherDetails,
    isDetailsLoading,
    isDetailsData,
  } = useContext(CandidateEntriesContext);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    }
  }, [location, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Personal Details</h4>
            {isDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="row g-3">
          {!isDetailsLoading && (
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="nk-block">
                {candidateOtherDetails.length !== 0 &&
                  candidateOtherDetails?.personal_details ? (
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Candidate Name</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.first_name &&
                            candidateOtherDetails?.personal_details?.first_name} {' '}
                          {candidateOtherDetails?.personal_details?.middle_name &&
                            candidateOtherDetails?.personal_details?.middle_name}{' '}
                          {candidateOtherDetails?.personal_details?.last_name &&
                            candidateOtherDetails?.personal_details?.last_name}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Email</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.email || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Mobile Number</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.mobile || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Gender</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.gender || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Current Location</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.current_location || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Date of Birth</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.date_of_birth || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Attachment</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.attachment ? (
                            <a
                              href={
                                candidateOtherDetails?.personal_details
                                  ?.attachment
                              }
                              class="caption-text text-break"
                              target="_blank"
                            >
                              View Attachment
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Skills</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details?.skills || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Instagram</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.instagram_url !== null  && candidateOtherDetails?.personal_details
                            ?.instagram_url !== "" ? (
                            <a
                              href={
                                candidateOtherDetails?.personal_details
                                  ?.instagram_url
                              }
                              class="caption-text text-break"
                              target="_blank"
                            >
                              Link
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Twitter</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.twitter_url !== null && candidateOtherDetails?.personal_details
                            ?.twitter_url !== "" ? (
                            <a
                              href={
                                candidateOtherDetails?.personal_details
                                  ?.twitter_url
                              }
                              class="caption-text text-break"
                              target="_blank"
                            >
                              Link
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Facebook</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.facebook_url !== null  && candidateOtherDetails?.personal_details
                            ?.facebook_url !== "" ? (
                            <a
                              href={
                                candidateOtherDetails?.personal_details
                                  ?.facebook_url
                              }
                              class="caption-text text-break"
                              target="_blank"
                            >
                              Link
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Linkedin</span>
                        <span className="profile-ud-value">
                          {candidateOtherDetails?.personal_details
                            ?.linkedin_url && candidateOtherDetails?.personal_details
                            ?.linkedin_url !== "" ? (
                            <a
                              href={
                                candidateOtherDetails?.personal_details
                                  ?.linkedin_url
                              }
                              class="caption-text text-break"
                              target="_blank"
                            >
                              Link
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="nk-tb-item">
                    <div className="nk-tb-col">
                      <span className="tb-product">
                        <span className="title">No Personal Information found</span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="col-12">
            <div className="form-group">
              {level !== 0 && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary me-3"
                  id="button-button-prevlevel"
                  onClick={() => prevLevel()}
                >
                  Prev
                </button>
              )}
              {sequenceList.length - 2 > level ? (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                  onClick={() => nextLevel()}
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
