import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const CandidateResumeEdit = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [filePreview, setFilePreview] = useState(null);

  const { getCandidateResume } = useContext(CandidateResumeContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const { jobOpeningDropdownData, isGenricDropdownDataLoading } = useContext(
    GenericDropdownContext
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const deleteCandidateResume = () => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url +
      `/api/candidateresume/${location.state.editdata.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    axios(config)
      .then((response) => {
        getCandidateResume();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the candidate resume",
        });
        console.log(JSON.stringify(response.data));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setActiveStatus(0);
          RefreshToken();
        } else {
          setActiveStatus(1);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the candidate resume",
          });
        }
      });
  };

  const deleteCandidateResumeAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCandidateResume();
      } else {
        setActiveStatus(1);
      }
    });
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    user.append("candidate_name", data.candidate_name);
    user.append("email", data.email);
    user.append("mobile", data.mobile);
    user.append("resume", data.resume);
    if (location.state?.data === "referral") {
      user.append("is_referral", "True");
      user.append("job_id", data.job_title.value);
    }
    user.append("updated_by_id", userID);

    const url =
      VARIABLES.url + `/api/candidateresume/${location.state.editdata.id}/`;
    console.log(url);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };
    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Sucessfully updated the candidate resume",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error.response?.data?.message || "Failed update candidate resume",
          });
        }
      });
  };

  useEffect(() => {
    console.log(location.state.editdata);
    const editedCandidateResume = location.state.editdata;
    if (editedCandidateResume) {
      var defaultValues = {};
      defaultValues.candidate_name = editedCandidateResume?.candidate_name;
      defaultValues.email = editedCandidateResume?.email;
      defaultValues.mobile = editedCandidateResume?.mobile?.slice(3);
      if (editedCandidateResume?.job_title) {
        const getJobTitle = {
          value: editedCandidateResume?.job_opening?.id,
          label: editedCandidateResume?.job_opening?.job_title,
        };
        defaultValues.job_title = getJobTitle;
      }
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> {location.state?.data === "referral" ? "Edit refer candidate" : "Edit Resume"} </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          {["candidate_resume_delete", "refer_candidate_delete"].some(
            (privilege) => privileges.includes(privilege)
          ) ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-delete-candidateresume"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteCandidateResumeAlert(e.target.checked);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-delete-candidateresume"
                ></label>
              </div>
            </div>
          ) : (
            " "
          )}
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                {location.state?.data === "referral" && (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Job Title <span className="text-danger">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={jobOpeningDropdownData?.map((info) => ({
                              value: info.id,
                              label:
                                info.job_title +
                                " " +
                                info.department_name +
                                " " +
                                info.team_name,
                            }))}
                            isLoading={isGenricDropdownDataLoading}
                            isClearable={true}
                            id="select-forms-job_title"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="job_title"
                        control={control}
                      />
                      {errors.job_title && (
                        <span className="fs-8 text-danger">
                          Job title is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Candidate Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="text-forms-candidatename"
                    placeholder="Enter candidate name"
                    {...register("candidate_name", { required: true })}
                  />
                  {errors.candidate_name && (
                    <span className="fs-8 text-danger">
                      Candidate name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Email
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="text-forms-email"
                    placeholder="Enter email"
                    {...register("email", {
                      required: "Email is required",
                      validate: (value) =>
                        value === value.toLowerCase() ||
                        "Email should only contain lowercase letters",
                    })}
                  />
                  {errors.email && (
                    <span className="fs-8 text-danger">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Mobile
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="text-forms-mobile"
                    placeholder="Enter mobile"
                    {...register("mobile", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: {
                        startsWithValidDigit: (value) =>
                          ["9", "8", "7", "6"].includes(
                            value.toString().charAt(0)
                          ) || "First digit should be 9, 8, 7, or 6",
                      },
                    })}
                  />
                  {errors.mobile && errors.mobile.type == "required" && (
                    <span className="fs-8 text-danger">Mobile is required</span>
                  )}
                  {errors.mobile && errors.mobile.type == "minLength" && (
                    <span className="fs-8 text-danger">
                      Number should be minimum 10 digits
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type == "maxLength" && (
                    <span className="fs-8 text-danger">
                      Number should be maximum 10 digits
                    </span>
                  )}
                  {errors.mobile &&
                    errors.mobile.type === "startsWithValidDigit" && (
                      <span className="fs-8 text-danger">
                        {errors.mobile.message}
                      </span>
                    )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Resume <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    name="resume"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value.type === "application/pdf",
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-resume"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                          handleResumeChange(e.target.files);
                        }}
                      />
                    )}
                  />
                  {errors.resume && errors.resume.type === "required" && (
                    <span className="fs-8 text-danger">Resume is required</span>
                  )}
                  {errors.resume && errors.resume.type === "validate" && (
                    <span className="fs-8 text-danger">
                      Please upload your document in pdf format
                    </span>
                  )}
                  {location.state.editdata?.resume && (
                    <span className="fs-8 pt-2">
                      Previously uploaded document :{" "}
                      <a
                        className="btn btn-primary mt-2"
                        id="button-button-viewresume"
                        href={location.state.editdata?.resume}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em
                          className="icon ni ni-file-pdf"
                          style={{ color: "white" }}
                        ></em>
                      </a>
                    </span>
                  )}
                  {filePreview && (
                    <a
                      className="btn btn-primary mt-2 ms-1"
                      href={filePreview}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview Uploaded File
                    </a>
                  )}
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editcandidateresume"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateResumeEdit;
