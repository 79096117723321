import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { JobAssignmentContext } from "../../Context/JobAssignment";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const JobAssignment = () => {
  const { isAccess } = useContext(RefreshTokenContext)
  const [searchAssignment, setSearchAssignment] = useState("");
  console.log(searchAssignment);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawAssignment,
    getAssignmentData,
    assignmentEntries,
    assignmentPageCount,
    setAssignmentEntries,
    assignmentCount,
    handleAssignmentClick,
    isAssignmentData,
    isAssignmentLoading,
  } = useContext(JobAssignmentContext);

  const handleEntries = (event) => {
    setAssignmentEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchAssignment(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAssignmentData(1, searchAssignment);
    } else {
      getAssignmentData(1);
      setSearchAssignment("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access")
      console.log(access)
      getAssignmentData(1, searchAssignment, access);
    } else {
      let access = secureLocalStorage.getItem("access")
      console.log(access)
      getAssignmentData(1, searchAssignment, access);
    }
  }, [assignmentEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Job Assignment</h3>
            {isAssignmentLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <!-- .nk-block-head-content --> */}
          {privileges.includes("job_assignment_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createjobassignment"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-createjobassignment"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createjobassignment"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-createjobassignment"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Assignment</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
          {/* <!-- .nk-block-head-content --> */}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>

      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchAssignment}
                      className="form-control"
                      placeholder="Search by assignment title, job title and department - team"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Assignment Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department - Team</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Assignment Document</span>
                      </div>
                      {privileges.includes("job_assignment_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawAssignment.length !== 0 ? (
                      rawAssignment.map((post, index) => (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.assignment_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {post?.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {post?.job_opening?.department?.department_display_name + " - " + post?.job_opening?.team?.team_display_name || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.assignment_document ? (
                                <a
                                  className="btn btn-primary"
                                  id="button-button-assignment_doc"
                                  href={post?.assignment_document}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <em
                                    className="icon ni ni-file-pdf"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          {privileges.includes("job_assignment_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editjobassignment"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editassignment"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isAssignmentData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={assignmentPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={assignmentPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleAssignmentClick(data, searchAssignment)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:{" "}
                      <span className="fw-bold">
                        {assignmentCount === null ? 0 : assignmentCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>{" "}
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={assignmentEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobAssignment;
