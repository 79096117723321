import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import Snackbar from "../../../Base/Snackbar";
import { StepperCandidate } from "../../../Context/StepperCandidate";
import { useLocation } from "react-router-dom";
import { VARIABLES } from "../../../Constants";
import axios from "axios";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";

const SmartHireForm = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present

  const {
    level,
    nextLevel,
    prevLevel,
    sequenceList,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { SHFInterviewQuestion, getSHFInterviewQuestions, isQuestionLoading } =
    useContext(InterviewQuestionContext);
  console.log(SHFInterviewQuestion); //all interview questionnaire data

  const { SHFData, getSHFData } = useContext(StepperCandidate);

  const { setSubmitMessage } = useContext(SnackbarContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  const FilledForm = SHFData[0]?.interview_question_score;

  console.log(SHFData);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;
  const job_opening_id = location.state?.job_opening_id;
  console.log(location.state);

  const name = location.state?.candidate_name;
  const email = location.state?.candidate_email;

  // const [isEditable, setIsEditable] = useState(
  //   SHFData[0]?.interview_question_score?.length === 0
  // );

  const isDefaultDisabled = SHFData[0]?.interview_question_score?.length > 0;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    const interviewQuestions = [...SHFInterviewQuestion];

    console.log(data);
    console.log(interviewQuestions);

    const questionsWithAnswers = [
      { question: "Name", answer: name ? name : "" },
      { question: "Email", answer: email ? email : "" },
    ];

    const interviewQuestionsWithAnswers = interviewQuestions?.map(
      (post, index) => {
        const question = post.question;
        const answer = data[`question${index}`];
        return { question, answer };
      }
    );

    questionsWithAnswers.push(...interviewQuestionsWithAnswers);

    var user = JSON.stringify({
      candidate_interview_id: SHFData[0]?.interview_details?.id,
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      question_answers: questionsWithAnswers,
      created_by_id: userID,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(user);

    const url = VARIABLES.url + "/api/smarthireform/?send_from=rms";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Success",
          });
          getWorkflowSession(candidate_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed",
        });
      });
  };

  // const toggleEdit = () => {
  //   setIsEditable(!isEditable);
  // };

  const handleFormSubmit = (data) => {
    if (FilledForm) {
      console.log("dirty");
      handleSubmit(onSubmit)(data);
    } else {
      console.log("good");
      nextLevel();
    }
  };

  useEffect(() => {
    const editedData = SHFData[0]?.interview_question_score;
    console.log(editedData);
    console.log(SHFData[0]);
    if (editedData) {
      console.log(editedData);
      const defaultValues = {};

      SHFInterviewQuestion?.map((question, index) => {
        defaultValues[`question${index}`] =
          editedData.find((item) => item.question === question.question)
            ?.answer || "";
      });

      reset(defaultValues);
    }
  }, [SHFData]);

  useEffect(() => {
    const defaultValues = {};
    defaultValues.email = email;
    defaultValues.name = name;
    reset(defaultValues);
  }, [name, email]);

  useEffect(() => {
    getSHFInterviewQuestions(job_opening_id);
    getSHFData(requisition_id, candidate_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Smart Hire Form </h4>
            {isQuestionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      {isQuestionLoading ? (
        <div></div>
      ) : (
        <>
          <div className="col-lg-12 col-md-12 col-sm-12">
            {SHFData[0]?.interview_details?.id ? (
              <>
                <div className="card">
                  <div className="card-inner">
                    <form
                      onSubmit={handleSubmit(handleFormSubmit)}
                      autoComplete="off"
                    >
                      <div className="row g-4 ">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email-forms-email"
                              placeholder="Enter email"
                              {...register("email")}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="text-forms-name"
                              placeholder="Enter name"
                              {...register("name")}
                              disabled
                            />
                          </div>
                        </div>

                        {SHFInterviewQuestion?.map((post, index) => (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                {post?.question}
                              </label>
                              <textarea
                                style={{ minHeight: "50px" }}
                                className="form-control"
                                id={`text-forms-question-${index}`}
                                placeholder="Your answer"
                                {...register(`question${index}`)}
                                disabled={isFormFilled}
                              />
                            </div>
                          </div>
                        ))}

                        <div className="col-12 d-flex">
                          <div className="form-group">
                            {level !== 0 && (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary me-3"
                                id="button-button-prevlevel"
                                onClick={() => prevLevel()}
                              >
                                Prev
                              </button>
                            )}
                          </div>
                          <div className="form-group">
                            {submitLoading ? (
                              <button className="btn text-dark" disabled>
                                Loading...
                              </button>
                            ) : (
                              <>
                                {sequenceList.length - 2 > level ? (
                                  isFormFilled ? (
                                    <button
                                      type="button"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-submitshf"
                                      onClick={() => nextLevel()}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-submitshf"
                                    >
                                      Next
                                    </button>
                                  )
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-submitshf"
                                  >
                                    Submit
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              "Smart hire form is not scheduled for this candidate."
            )}
          </div>
        </>
      )}
      <Snackbar />
    </>
  );
};

export default SmartHireForm;
