import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { SettingContext } from "../../Context/SettingsContext";

const VersionTrackerCreate = () => {
  const { getVersionTracker } = useContext(SettingContext);

  const userID = secureLocalStorage.getItem("userID");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(null);

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const onSubmit = (data) => {
    let user = new FormData();
    user.append("version_number", data?.version_number);
    user.append("release_date", data?.release_date);
    user.append("release_note", data?.release_note);
    user.append("created_by_id", userID);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + `/api/versiontracker/`;

    var config = {
      method: "post",
      url: url,
      headers: {},
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getVersionTracker();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Version updated successfully",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update version",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Add Release </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Version Number <span className="text-danger">*</span></label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter version no."
                    id="text-form-version_number"
                    {...register("version_number", {
                      required: true,
                    })}
                  />
                  {errors.version_number && (
                    <span className="fs-8 text-danger">
                      Version no. is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Release Date <span className="text-danger">*</span></label>
                  
                  <input
                    type="datetime-local"
                    className="form-control"
                    placeholder="Enter release no."
                    id="text-form-release_date"
                    {...register("release_date", {
                      required: true,
                    })}
                  />
                  {errors.release_date && (
                    <span className="fs-8 text-danger">
                      Release date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Release Note <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    name="release_note"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value.type === "application/pdf",
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-release_note"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                          handleResumeChange(e.target.files);
                        }}
                      />
                    )}
                  />
                  {errors.release_note && errors.release_note.type === "required" && (
                    <span className="fs-8 text-danger">Release note is required</span>
                  )}
                  {errors.release_note && errors.release_note.type === "validate" && (
                    <span className="fs-8 text-danger">
                      Please upload your document in pdf format
                    </span>
                  )}
                  {filePreview && (
                    <a
                      className="btn btn-primary mt-2"
                      href={filePreview}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview Uploaded File
                    </a>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button_loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editversion"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VersionTrackerCreate;
