import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import { JobOpeningContext } from "../../Context/JobOpeningContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const WriteRequestedJD = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  let location = useLocation();
  let navigate = useNavigate();
  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const { getJobDescriptionData } = useContext(JobOpeningContext);

  console.log(location.state.editpost);

  const { isGenricDropdownDataLoading, workflowDropdownData } = useContext(
    GenericDropdownContext
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const editDescription = watch("job_description");
  const editRequirement = watch("job_requirement");

  const onDescriptionChange = (editDescription) => {
    setValue("job_description", editDescription);
  };
  const onRequirementChange = (editRequirement) => {
    setValue("job_requirement", editRequirement);
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var job_posting = JSON.stringify({
      job_description: editDescription,
      job_requirement: editRequirement,
      workflow_id: data.workflow_assigned.value,
      experience: data.required_experience,
      location: data.job_location,
      updated_by_id: userID,
    });

    console.log(job_posting);
    const url =
      VARIABLES.url + `/api/jobopening/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: job_posting,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        getJobDescriptionData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Job Description updated successfully",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update Job Description",
          });
        }
      });
  };

  useEffect(() => {
    console.log(location.state?.editpost);
    const editedJobPost = location.state?.editpost;
    if (editedJobPost) {
      var defaultValues = {};
      defaultValues.job_description = editedJobPost?.job_description;
      defaultValues.job_requirement = editedJobPost?.job_requirement;
      defaultValues.required_experience = editedJobPost?.experience;
      defaultValues.job_location = editedJobPost?.location;

      if (editedJobPost?.workflow !== null) {
        const getWorkflow = {
          value: editedJobPost?.workflow?.id,
          label: editedJobPost?.workflow?.workflow_name,
        };
        defaultValues.workflow_assigned = getWorkflow;
      }
      reset(defaultValues);
    }
  }, [location]);

  useEffect(() => {
    register("job_description", {
      validate: (value) => value !== "<p><br></p>",
      required: true,
    });
  }, [register]);

  useEffect(() => {
    register("job_requirement", {
      validate: (value) => value !== "<p><br></p>",
      required: true,
    });
  }, [register]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Job Description</h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Description<span className="text-danger"> *</span>
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={editDescription}
                      onChange={onDescriptionChange}
                      id="textarea-forms-Job_description"
                    />
                    <p className="fs-8 text-danger">
                      {errors.job_description && "Job description is required"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Requirement<span className="text-danger"> *</span>
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={editRequirement}
                      onChange={onRequirementChange}
                      id="textarea-forms-job_requirement"
                    />
                    <p className="fs-8 text-danger">
                      {errors.job_requirement && "Job requirement is required"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Required Experience (in Years)
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="number-forms-required_experience"
                      placeholder="Enter required experience"
                      onWheel={(e) => e.target.blur()}
                      {...register("required_experience", {
                        required: true,
                      })}
                    />
                    {errors.required_experience &&
                      errors.required_experience.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter required experience
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Location<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-job_location"
                      placeholder="Enter job location"
                      {...register("job_location", {
                        required: true,
                      })}
                    />
                    {errors.job_location && (
                      <span className="fs-8 text-danger">
                        Job Location is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Assign Workflow<span className="text-danger"> *</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={workflowDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.workflow_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-workflow_assigned"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="workflow_assigned"
                      control={control}
                    />
                    {errors.workflow_assigned && (
                      <span className="fs-8 text-danger">
                        Workflow for job is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editjobopening"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WriteRequestedJD;
