import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";

const AddTechnicalQuestion = (props) => {
  const { isDropdownDataLoading, interviewCategoryDropdownData } = useContext(
    TypemasterDropdownContext
  );
  const { getTechnicalInterviewQuestions } = useContext(
    InterviewQuestionContext
  );
  const { RefreshToken } = useContext(RefreshTokenContext);

  const { setSubmitMessage } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const userID = secureLocalStorage.getItem("userID");

  const location = useLocation();

  // const onSubmit = (data) => {
  //   console.log("first");
  //   const newQuestion = {
  //     question: data.question,
  //     category: [data.category.value],
  //     answer: data.answer,
  //     interview_type: ["TI"],
  //   };
  //   console.log(newQuestion);
  //   props.setAddTechnicalQuestion([...props.addTechnicalQuestion, newQuestion]);
  //   reset();
  // };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    var user = JSON.stringify({
      job_opening_id: [location.state?.job_opening_id],
      interview_type: ["Technical"],
      category: [data.category.value],
      question: data.question,
      answer: data.answer,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/interviewquestionnaire/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);
    props.setNewQueSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        getTechnicalInterviewQuestions(location.state?.job_opening_id);
        console.log(JSON.stringify(response.data));
        props.setNewQueSubmitLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "New question added to this interview",
        });
        reset();
      })
      .catch((error) => {
        props.setNewQueSubmitLoading(false);
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Failed to add new question added to this interview",
        });
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="addtechquestion"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add technical question
              </h5>

              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Question <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-question"
                        placeholder="Enter Question"
                        {...register("question", { required: true })}
                      />
                      {errors.question && (
                        <p className="fs-8 text-danger">Question is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Answer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-answer"
                        placeholder="Enter answer"
                        {...register("answer")}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      {" "}
                      Category
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={interviewCategoryDropdownData?.map(
                            (info) => ({
                              value: info.value,
                              label: info.name,
                            })
                          )}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-catogory"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="category"
                      control={control}
                    />
                    {errors.category && (
                      <span className="fs-8 text-danger">
                        Category is required{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  id="submit-button-addtechquestion"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTechnicalQuestion;
