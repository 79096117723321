import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const DraftedCandidate = () => {
    const { isAccess } = useContext(RefreshTokenContext);
    const [searchResume, setSearchResume] = useState("");
    console.log(searchResume);
    const privileges = secureLocalStorage.getItem("privileges");
    const location = useLocation();
    console.log(location.state);
    const {
        rawRejectedCandidate,
        getRejectedCandidate,
        rejectedCandidateEntries,
        rejectedCandidatePageCount,
        setRejectedCandidateEntries,
        rejectedCandidateCount,
        handleRejectedCandidateClick,
        isRejectedCandidateData,
        isRejectedCandidateLoading
    } = useContext(CandidateResumeContext);

    const handleEntries = (event) => {
        setRejectedCandidateEntries(event.target.value);
    };

    const onChangeHandler = (event) => {
        setSearchResume(event.target.value);
    };

    const onSearchHandler = (method, event) => {
        event.preventDefault();
        console.log("reqID: ",location.state?.editpost?.requisition?.id )
        if (method === "search") {
            getRejectedCandidate(1, searchResume, location.state?.editpost?.requisition?.id, 0);
        } else {
            getRejectedCandidate(1, "", location.state?.editpost?.requisition?.id, 0);
            setSearchResume("");
        }
    };

    const formatName = (name) => {
        if (!name) {
            return "-";
        } else {
            const parts = name.split(" ");
            console.log(parts);
            const firstName = parts[0]
                ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
                : "";
            const lastName = parts[1]
                ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
                : "";
            return `${firstName} ${lastName}`;
        }
    };

    useEffect(() => {
        if (isAccess) {
            let access = secureLocalStorage.getItem("access");
            getRejectedCandidate(1, searchResume, location.state?.editpost?.requisition?.id, 0, access);
        } else {
            let access = secureLocalStorage.getItem("access");
            getRejectedCandidate(1, searchResume, location.state?.editpost?.requisition?.id, 0, access);
        }
    }, [rejectedCandidateEntries, isAccess]);

    return (
        <>
            <WelcomeMessage />
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                        <h3 className="nk-block-title page-title">
                            Drafted Candidates
                        </h3>
                        {isRejectedCandidateLoading ? (
                            <div class="spinner-border text-primary ms-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            <div className="nk-block">
                <div className="card mb-3">
                    <div className="card-inner-group">
                        <div className="card-inner border-bottom-0">
                            <div className="form-control-wrap">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={searchResume}
                                            className="form-control"
                                            placeholder="Search by candidate name,email"
                                            onChange={onChangeHandler}
                                            id="text-form-input-search"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-dim"
                                                id="button-button-search"
                                                onClick={(e) => onSearchHandler("search", e)}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-dim"
                                                id="button-button-view"
                                                onClick={(e) => onSearchHandler("view", e)}
                                            >
                                                Clear Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <div className="nk-tb-list">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col">
                                                <span>Drafted On</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Candidate Name</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Email-ID</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Mobile no.</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Stage</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Resume</span>
                                            </div>
                                        </div>
                                        {rawRejectedCandidate.length !== 0 ? (
                                            rawRejectedCandidate.map((post, index) => (
                                                <div className="nk-tb-item" key={index}>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-product">
                                                            <span className="title">
                                                                {post?.recruitment_status_date}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-product">
                                                            <span className="title">
                                                                {formatName(post?.candidate_name)}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            <span className="title">
                                                                {post?.email || "-"}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            <span className="title">
                                                                {post?.mobile || "-"}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            <span className="title">
                                                                {post?.stage || "-"}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                            {post?.resume ? (
                                                                <a
                                                                    className="btn btn-primary"
                                                                    id="button-button-viewresume"
                                                                    href={post?.resume}
                                                                    rel="noreferrer"
                                                                >
                                                                    <em
                                                                        className="icon ni ni-file-docs"
                                                                        style={{ color: "white" }}
                                                                    ></em>
                                                                </a>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        ) : isRejectedCandidateData ? (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">
                                                            Good job! You are all caught up.
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Loading...</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="nk-block-between-md g-3">
                                <div className="g">
                                    <ReactPaginate
                                        key={rejectedCandidatePageCount}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={rejectedCandidatePageCount}
                                        marginPagesDisplayed={2}
                                        onPageChange={(data) =>
                                            handleRejectedCandidateClick(data, searchResume, location.state?.editpost?.id, 0)
                                        }
                                        containerClassName={
                                            "pagination justify-content-center justify-content-md-start"
                                        }
                                        pageClassName={"paginate_button page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"paginate_button page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                                <div className="g">
                                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                        <div>
                                            Total entries:
                                            <span className="fw-bold">
                                                {rejectedCandidateCount === null
                                                    ? 0
                                                    : rejectedCandidateCount}
                                            </span>
                                        </div>
                                        <div>No of entries per page:</div>
                                        <div>
                                            <select
                                                className="form-select js-select2"
                                                data-search="on"
                                                data-dropdown="xs center"
                                                value={rejectedCandidateEntries}
                                                onChange={handleEntries}
                                                id="select-form-enteries"
                                            >
                                                <option value="5">5</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DraftedCandidate;
