import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import { MasterTableContext } from "../../Context/MasterTableContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const CreateAgencyMaster = () => {
  const { getAgencyData } = useContext(MasterTableContext);
  const { RefreshToken, isAccess } = useContext(RefreshTokenContext);

  //DROPDOWN DATA
  const { isDropdownDataLoading, agencyTypeDropdownData } = useContext(TypemasterDropdownContext);
  const { getGenericDropdownData } = useContext(GenericDropdownContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    var user = JSON.stringify({
      agency_name: data.agency_name,
      preference: data.preference,
      agency_type: data.agency_type.value,
      created_by_id: userID,
      updated_by_id: userID,
    });
    console.log(user);

    const url = VARIABLES.url + `/api/agencymaster/`;
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        getGenericDropdownData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Agency successfully created",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create agency",
          });
        }
      });
  };

  // useEffect(() => {
  //   if(isAccess) {
  //     onSubmit(data)
  //   }
  // },[isAccess])

  useEffect(() => {
    getAgencyData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Add Agency </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Agency Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-agency_name"
                      placeholder="Enter agency name"
                      {...register("agency_name", { required: true })}
                    />
                    {errors.agency_name && (
                      <span className="text-danger">
                        Agency name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Preference <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-preference"
                      placeholder="Enter preference"
                      {...register("preference", { required: true })}
                    />
                    {errors.preference && (
                      <span className="text-danger">
                        Preference is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Agency Type <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={agencyTypeDropdownData?.map((info) => ({
                            value: info.value,
                            label: info.name,
                          }))}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-agency_type"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="agency_type"
                      control={control}
                    />
                    {errors.agency_type && (
                      <p className="fs-8 text-danger">
                        Agency type is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createagency"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateAgencyMaster;
