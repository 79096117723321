import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";

const EmailNotificationEdit = () => {
    const userID = secureLocalStorage.getItem("userID");

    const { getEmailNotificationData } = useContext(NotificationsContext);
    const { usersDropdownData, isGenricParamsDropdownDataLoading, roleDropdownData } = useContext(GenericDropdownContext);

    console.log("usersDropdownData: ", usersDropdownData)
    console.log("roleDropdownData: ", roleDropdownData)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm();

    let location = useLocation();
    let navigate = useNavigate();

    const [submitMessage, setSubmitMessage] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [showFullMessage, setShowFullMessage] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState({
        to_user_id: [],
        cc_user_id: [],
        bcc_user_id: [],
    });

    const [selectedRoles, setSelectedRoles] = useState({
        to_role_id: [],
        cc_role_id: [],
        bcc_role_id: [],
    });

    const handleUserChange = (selectedOption, fieldName) => {
        setSelectedUsers((prev) => ({
            ...prev,
            [fieldName]: selectedOption || [],
        }));
    };

    const handleRoleChange = (selectedOption, fieldName) => {
        setSelectedRoles((prev) => ({
            ...prev,
            [fieldName]: selectedOption || [],
        }));
    };

    // Filter users to remove already selected users in other dropdowns
    const filterUserOptions = (dropdownName) => {
        const selectedInOtherDropdowns = Object.keys(selectedUsers)
            .filter((key) => key !== dropdownName)
            .flatMap((key) => selectedUsers[key].map((opt) => opt.value));

        return usersDropdownData?.map((info) => ({
            value: info?.user.id,
            label: `${info?.user.first_name} ${info?.user.last_name}`,
        })).filter((option) => !selectedInOtherDropdowns.includes(option.value));
    };

    // Filter roles to remove already selected roles in other dropdowns
    const filterRoleOptions = (dropdownName) => {
        const selectedInOtherDropdowns = Object.keys(selectedRoles)
            .filter((key) => key !== dropdownName)
            .flatMap((key) => selectedRoles[key].map((opt) => opt.value));

        return roleDropdownData?.map((info) => ({
            value: info.id,
            label: info.role_name,
        })).filter((option) => !selectedInOtherDropdowns.includes(option.value));
    };

    const toggleMessage = () => {
        setShowFullMessage(!showFullMessage);
    };

    const emailMessage =
        location?.state?.editpost?.message || "";
    const shortEmailMessage =
        emailMessage.length > 325
            ? `${emailMessage.substring(0, 325)} . . .`
            : emailMessage;

    const onSubmit = (data) => {
        console.log(data)
        var user = JSON.stringify({
            to_user_id: data.to_user_id?.map((info) => info.value),
            to_role_id: data.to_role_id?.map((info) => info.value),
            to_specific_user: data.to_user_id && data.to_user_id.length !== 0 ? true : false,
            cc_user_id: data.cc_user_id?.map((info) => info.value),
            cc_role_id: data.cc_role_id?.map((info) => info.value),
            cc_specific_user: data.cc_user_id && data.cc_user_id.length !== 0 ? true : false,
            bcc_user_id: data.bcc_user_id?.map((info) => info.value),
            bcc_role_id: data.bcc_role_id?.map((info) => info.value),
            bcc_specific_user: data.bcc_user_id && data.bcc_user_id.length !== 0 ? true : false,
            updated_by_id: userID,
        });

        const url =
            VARIABLES.url + `/api/email-notification-config/${location.state.editpost.id}/`;

        var config = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: user,
        };

        setSubmitLoading(true);
        axios(config)
            .then((response) => {
                setSubmitLoading(false);
                console.log(JSON.stringify(response.data));
                getEmailNotificationData();
                setSubmitMessage({
                    type: "success",
                    icon: "check",
                    message: "Notification updated successfully",
                });
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
                reset();
            })
            .catch((error) => {
                setSubmitLoading(false);
                console.log(error);
                setSubmitMessage({
                    type: "danger",
                    icon: "cross",
                    message: "Failed to update notification",
                });
            });
    };

    useEffect(() => {
        console.log(location.state.editpost);
        const editedNotification = location.state.editpost;
        if (editedNotification) {
            var defaultValues = {};

            if (editedNotification?.to_user && editedNotification?.to_user?.length > 0) {
                const getToUsers = editedNotification?.to_user?.map((user) => ({
                    value: user.id,
                    label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
                }));
                defaultValues.to_user_id = getToUsers;
                setSelectedUsers((prev) => ({ ...prev, to_user_id: getToUsers }));
            }

            if (editedNotification?.to_role && editedNotification?.to_role?.length > 0) {
                const getToRole = editedNotification?.to_role?.map((user) => ({
                    value: user.id,
                    label: user?.role_name,
                }));
                defaultValues.to_role_id = getToRole;
                setSelectedRoles((prev) => ({ ...prev, to_role_id: getToRole }));
            }

            if (editedNotification?.cc_user && editedNotification?.cc_user?.length > 0) {
                const getCCUsers = editedNotification?.cc_user?.map((user) => ({
                    value: user.id,
                    label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
                }));
                defaultValues.cc_user_id = getCCUsers;
                setSelectedUsers((prev) => ({ ...prev, cc_user_id: getCCUsers }));
            }

            if (editedNotification?.cc_role && editedNotification?.cc_role?.length > 0) {
                const getCCRole = editedNotification?.cc_role?.map((user) => ({
                    value: user.id,
                    label: user?.role_name,
                }));
                defaultValues.cc_role_id = getCCRole;
                setSelectedRoles((prev) => ({ ...prev, cc_role_id: getCCRole }));
            }

            if (editedNotification?.bcc_user && editedNotification?.bcc_user?.length > 0) {
                const getBCCUsers = editedNotification?.bcc_user?.map((user) => ({
                    value: user.id,
                    label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
                }));
                defaultValues.bcc_user_id = getBCCUsers;
                setSelectedUsers((prev) => ({ ...prev, bcc_user_id: getBCCUsers }));
            }

            if (editedNotification?.bcc_role && editedNotification?.bcc_role?.length > 0) {
                const getBCCRole = editedNotification?.bcc_role?.map((user) => ({
                    value: user.id,
                    label: user?.role_name,
                }));
                defaultValues.bcc_role_id = getBCCRole;
                setSelectedRoles((prev) => ({ ...prev, bcc_role_id: getBCCRole }));
            }


            reset(defaultValues);

        }
    }, [location.state.editpost, reset]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content">
                        <h4 className="nk-block-title"> Edit Email Notification </h4>
                    </div>
                    <div class="nk-block-head-content">
                        <a
                            id="button-back"
                            className="btn btn-light d-none d-sm-inline-flex"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                        </a>
                        <a
                            id="button-back"
                            className="btn btn-icon btn-light d-inline-flex d-sm-none"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-inner">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="row g-4">
                                <div className="col-lg-12 d-flex" style={{ alignItems: "baseline" }}>
                                    <h6>Email Type : </h6>
                                    <p className="ms-2 fs-5 fw-bold">{location.state.editpost?.email_type}</p>
                                </div>

                                <div className="col-lg-12 d-flex" style={{ alignItems: "baseline" }}>
                                    <h6 >Subject :</h6>
                                    <p className="ms-2 fs-5 fw-bold"> {location.state.editpost?.subject}</p>
                                </div>

                                <div className="col-lg-12 d-flex" style={{ alignItems: "baseline" }}>
                                    <h6 style={{ width: "90px", textWrap: "nowrap" }}>Message :</h6>
                                    <p className="ms-1 fw-bold">
                                        <p
                                            className="m-0"
                                            dangerouslySetInnerHTML={{
                                                __html: showFullMessage
                                                    ? emailMessage
                                                    : shortEmailMessage,
                                            }}
                                        />
                                        {emailMessage.length > 325 && (
                                            <button
                                                type="button"
                                                className="badge bg-info"
                                                onClick={toggleMessage}
                                            >
                                                {showFullMessage ? "show less" : "show more "}
                                            </button>
                                        )}
                                    </p>
                                </div>

                                <div className="col-lg-12">
                                    <h6>Recipient Details:</h6>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Users</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={usersDropdownData?.map((info) => ({
                                                    //     value: info?.user.id,
                                                    //     label: `${info?.user.first_name} ${info?.user.last_name}`,
                                                    // }))}
                                                    options={filterUserOptions("to_user_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms1-user_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleUserChange(selected, "to_user_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="to_user_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Role</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={roleDropdownData?.map((info) => ({
                                                    //     value: info.id,
                                                    //     label: info.role_name,
                                                    // }))}
                                                    options={filterRoleOptions("to_role_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms1-role_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleRoleChange(selected, "to_role_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="to_role_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <h6>CC Details:</h6>
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Users</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={usersDropdownData?.map((info) => ({
                                                    //     value: info?.user.id,
                                                    //     label: `${info?.user.first_name} ${info?.user.last_name}`,
                                                    // }))}
                                                    options={filterUserOptions("cc_user_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms2-user_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleUserChange(selected, "cc_user_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="cc_user_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Role</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={roleDropdownData?.map((info) => ({
                                                    //     value: info.id,
                                                    //     label: info.role_name,
                                                    // }))}
                                                    options={filterRoleOptions("cc_role_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms2-role_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleRoleChange(selected, "cc_role_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="cc_role_id"
                                            control={control}
                                        />
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <h6>BCC Details:</h6>
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Users</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={usersDropdownData?.map((info) => ({
                                                    //     value: info?.user.id,
                                                    //     label: `${info?.user.first_name} ${info?.user.last_name}`,
                                                    // }))}
                                                    options={filterUserOptions("bcc_user_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms3-user_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleUserChange(selected, "bcc_user_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="bcc_user_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Role</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    // options={roleDropdownData?.map((info) => ({
                                                    //     value: info.id,
                                                    //     label: info.role_name,
                                                    // }))}
                                                    options={filterRoleOptions("bcc_role_id")}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms3-role_id"
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleRoleChange(selected, "bcc_role_id");
                                                        field.onChange(selected);
                                                    }}
                                                />
                                            )}
                                            defaultValue=""
                                            name="bcc_role_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-light me-3"
                                            id="submit-button-backbutton"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                        {submitLoading ? (
                                            <button
                                                id="button_loading"
                                                className="btn text-dark"
                                                disabled
                                            >
                                                Loading...
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary"
                                                id="submit-button-editnotification"
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                {submitMessage && (
                    <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                        <div className="gy-4">
                            <div className="example-alert">
                                <div
                                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                >
                                    <em
                                        className={`icon ni ni-${submitMessage.icon}-circle`}
                                    ></em>
                                    <strong>{submitMessage.message}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailNotificationEdit;
