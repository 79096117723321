import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import AssignUserRole from "./AssignUserRole";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import CreateOfficialEmail from "./CreateOfficialEmail";

const UserManagement = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchUser, setSearchUser] = useState("");
  console.log(searchUser);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawUsersDetails,
    getUserDetailsData,
    usersEntries,
    usersPageCount,
    setUsersEntries,
    usersCount,
    handlePageClick,
    isUsersData,
    isUsersLoading,
  } = useContext(UserDetailsContext);

  const [roleData, setRoleData] = useState([]);
  const [emailData, setEmailData] = useState([]);

  const handleEntries = (event) => {
    setUsersEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchUser(event.target.value);
  };

  const handleRoleClick = () => {
    document.getElementById("button-button-roleedit").click();
  };

  const handleEmailClick = () => {
    document.getElementById("button-button-emailedit").click();
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getUserDetailsData(1, searchUser);
    } else {
      getUserDetailsData(1);
      setSearchUser("");
    }
  };

  const [showAllRoles, setShowAllRoles] = useState({});
  console.log(showAllRoles);

  const toggleShowAllRoles = (user) => {
    setShowAllRoles((prevExpandedRoles) => ({
      ...prevExpandedRoles,
      [user]: !prevExpandedRoles[user],
    }));
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getUserDetailsData(1, searchUser, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getUserDetailsData(1, searchUser, access);
    }
  }, [usersEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">User Management</h3>
            {isUsersLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {privileges.includes("usermanagement_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createuser"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-createuser"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createuser"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-createuser"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create User</span>
              </NavLink>
            </div>
          ) : (
            " "
          )} */}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchUser}
                      className="form-control"
                      placeholder="Search by email, name, mobile (e.g. 9xxxxxxxxx; do not include +91) and role"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Email</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Mobile Number</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Role</span>
                      </div>
                      {privileges.includes("usermanagement_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="nk-tb-col">
                        <span>Official Email</span>
                      </div>
                    </div>

                    {rawUsersDetails.length !== 0 ? (
                      rawUsersDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.user.email || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.user.first_name || post.user.last_name
                                  ? post.user.first_name[0].toUpperCase() +
                                    post.user.first_name.slice(1) +
                                    " " +
                                    post.user.last_name[0].toUpperCase() +
                                    post.user.last_name.slice(1)
                                  : "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">{post.mobile || "-"}</span>
                          </div>

                          <div className="nk-tb-col">
                            {post.role.length !== 0 ? (
                              <>
                                {showAllRoles[post.user.id] ? (
                                  post.role.map((rl) => (
                                    <span className="tb-lead" key={rl.role_id}>
                                      {/* {privileges.includes(
                                        "usermanagement_update"
                                      ) ? (
                                        <NavLink
                                          onClick={() => {
                                            handleRoleClick(rl);
                                            setRoleData(post);
                                          }}
                                        >
                                          {rl.role_name}
                                        </NavLink>
                                      ) : (
                                        <>{rl.role_name}</>
                                      )} */}
                                      {rl.role_name}
                                    </span>
                                  ))
                                ) : (
                                  <span
                                    className="tb-lead"
                                    key={post.role[0].role_id}
                                  >
                                    {/* {privileges.includes(
                                      "usermanagement_update"
                                    ) ? (
                                      <NavLink
                                        onClick={() => {
                                          handleRoleClick(post.role[0]);
                                          setRoleData(post);
                                        }}
                                      >
                                        {post.role[0].role_name}
                                      </NavLink>
                                    ) : ( */}
                                    <>{post.role[0].role_name}</>
                                    {/* )} */}
                                  </span>
                                )}
                                {post.role.length > 1 && (
                                  <button
                                    onClick={() =>
                                      toggleShowAllRoles(post.user.id)
                                    }
                                    className="badge bg-info"
                                  >
                                    {showAllRoles[post.user.id]
                                      ? "Show less"
                                      : "Show all"}
                                  </button>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>

                          {privileges.includes("usermanagement_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                onClick={() => {
                                  handleRoleClick();
                                  setRoleData(post);
                                }}
                              >
                                <span className="btn btn-primary btn-sm text-light">
                                  Edit
                                </span>
                              </NavLink>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="nk-tb-col">
                            {privileges.includes(
                              "usermanagement_update_official_email"
                            ) &&
                            post?.role?.some(
                              (rl) => rl.role_value === "candidate"
                            ) ? (
                              <span className="tb-lead">
                                {post?.official_email ? (
                                  <NavLink
                                    onClick={() => {
                                      handleEmailClick();
                                      setEmailData(post);
                                    }}
                                  >
                                    {post?.official_email}
                                  </NavLink>
                                ) : (
                                  <div
                                    state={{ editpost: post }}
                                    className="btn btn-sm btn-primary"
                                    id="button-button-editposition"
                                    data-bs-toggle="modal"
                                    data-bs-target="#emailedit"
                                    onClick={() => {
                                      handleEmailClick();
                                      setEmailData(post);
                                    }}
                                  >
                                    <em className="icon ni ni-edit"></em>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span className="tb-lead">
                                {post?.official_email || "-"}
                              </span>
                            )}
                          </div>
                        </div>
                      ))
                    ) : isUsersData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">
                              Good job! You are all caught up.
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={usersPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={usersPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) => handlePageClick(data, searchUser)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {usersCount === null ? 0 : usersCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={usersEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        hidden
        id="button-button-roleedit"
        data-bs-toggle="modal"
        data-bs-target="#roleedit"
      ></div>
      <div
        hidden
        id="button-button-emailedit"
        data-bs-toggle="modal"
        data-bs-target="#emailedit"
      ></div>
      <AssignUserRole roleData={roleData} />
      <CreateOfficialEmail emailData={emailData} />
    </>
  );
};

export default UserManagement;
