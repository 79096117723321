import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { MasterTableContext } from "../../Context/MasterTableContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const Position = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchPosition, setSearchPosition] = useState("");
  console.log(searchPosition);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawPosition,
    getPositionData,
    positionEntries,
    positionPageCount,
    setPositionEntries,
    positionCount,
    handlePositionClick,
    isPositionData,
    isPositionLoading,
  } = useContext(MasterTableContext);

  const handleEntries = (event) => {
    setPositionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchPosition(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getPositionData(1, searchPosition);
    } else {
      getPositionData(1);
      setSearchPosition("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getPositionData(1, searchPosition, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getPositionData(1, searchPosition, access);
    }
  }, [positionEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Position</h3>
            {isPositionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("position_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/mastercombinedform"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-addposition"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/mastercombinedform"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-addposition"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Position</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchPosition}
                      className="form-control"
                      placeholder="Search by position name, position display name, team and department"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Position Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Position Display Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Team</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      {privileges.includes("position_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                      {/* To-Do: Add privilege */}
                      {/* {privileges.includes("master_position_update") ? ( */}
                      {/* <div className="nk-tb-col">
                        <span>Master Edit</span>
                      </div> */}
                      {/* ) : (
                        " "
                      )} */}
                    </div>

                    {rawPosition.length > 0 ? (
                      rawPosition.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.position_name || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.position_display_name || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {privileges.includes("job_opening_retrieve") ? (
                                <NavLink to="/team">
                                  <span className="nk-menu-text">
                                    {post.team?.team_display_name || "-"}
                                  </span>
                                </NavLink>
                              ) : (
                                <> {post.team?.team_display_name || "-"}</>
                              )}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {["department_retrieve"].some((privilege) =>
                                privileges.includes(privilege)
                              ) ? (
                                <NavLink to="/department">
                                  <span className="nk-menu-text">
                                    {post.department?.department_display_name ||
                                      "-"}
                                  </span>
                                </NavLink>
                              ) : (
                                <>
                                  {post.department?.department_display_name ||
                                    "-"}
                                </>
                              )}
                            </span>
                          </div>

                          {privileges.includes("position_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editposition"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editposition"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {/* {privileges.includes("master_position_update") ? ( */}
                          {/* <div className="nk-tb-col">
                            <NavLink
                              to={"/mastercombinedform"}
                              state={{ editposition: post }}
                              className="btn btn-sm btn-primary"
                              id="button-button-editposition"
                            >
                              <em className="icon ni ni-edit"></em>
                            </NavLink>
                          </div> */}
                          {/* ) : (
                            " "
                          )} */}
                        </div>
                      ))
                    ) : isPositionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">
                              Good job! You are all caught up.
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={positionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={positionPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handlePositionClick(data, searchPosition)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      No of entries :
                      <span className="fw-bold">
                        {positionCount === null ? 0 : positionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={positionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Position;
