import React from "react";
import WelcomeMessage from "../../Base/WelcomeMessage";
import NotificationMapper from "./NotificationMapper";
import WhatsAppNotification from "./WhatsAppNotification";
import EmailNotification from "./EmailNotification";

const MapperMaster = () => {
    return (
        <>
            <WelcomeMessage />
            <div className="nk-block">
                <div className="card">
                    <div className="p-2">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1">App</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tabItem2">Email</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tabItem3">WhatsApp</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="tabItem1">
                                <NotificationMapper />
                            </div>
                            <div class="tab-pane" id="tabItem2">
                                <EmailNotification />
                            </div>
                            <div class="tab-pane" id="tabItem3">
                                <WhatsAppNotification />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapperMaster;
