import React, { useEffect, useState, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import moment from "moment";
import { VARIABLES } from "../../Constants";
import axios from "axios";

const RE3 = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const [searchRE3, setSearchRE3] = useState("");
  console.log(searchRE3);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  const {
    rawRE3Data,
    getRE3Data,
    re3Entries,
    re3PageCount,
    setRE3Entries,
    re3Count,
    handleRE3Click,
    isRE3Data,
    isRE3Loading,
  } = useContext(TAMRE1RE2RE3Context);

  console.log(rawRE3Data);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const location = useLocation();

  const handleEntries = (event) => {
    setRE3Entries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRE3(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRE3Data(1, searchRE3);
    } else {
      getRE3Data(1);
      setSearchRE3("");
    }
  };

  const onTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: data?.requisition?.id,
      stage: "RE3 Outreach",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getRE3Data(1, searchRE3, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getRE3Data(1, searchRE3, access);
    }
  }, [re3Entries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Agency Outreach
            </h3>
            {isRE3Loading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRE3}
                      className="form-control"
                      placeholder="Search using Requisition ID (e.g., 126), Job Title, Department and Priority"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Approved on</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Priority</span>
                        <br />
                        <span>(Set by TAM)</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Pending Since (Days)</span>
                        <br />
                        <span>for outreach</span>
                      </div>

                      {location.state?.action !== 'Upload' &&
                        <>
                          {privileges.includes("agency_outreach_resume_upload") ? (
                            <div className="nk-tb-col">
                              <span> Outreach </span>
                              <br />
                              <span>(Pending to agencies)</span>
                            </div>
                          ) : (
                            " "
                          )}
                        </>
                      }
                      {location.state?.action === 'Upload' &&
                        <>
                          {privileges.includes("agency_outreach_resume_upload") ? (
                            <div className="nk-tb-col">
                              <span> Upload Resume </span>
                            </div>
                          ) : (
                            " "
                          )}
                        </>
                      }


                      {/* {privileges.includes("error_resume_download") ? (
                        <div className="nk-tb-col">
                          <span>Error Resume Zip Download</span>
                        </div>
                      ) : (
                        " "
                      )} */}
                    </div>

                    {rawRE3Data.length !== 0 ? (
                      rawRE3Data.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {moment(post?.requisition?.requisition_acceptance_on).format('MMMM Do YYYY, h:mm a')}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name +
                                  post?.requisition?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/rerequisitiondetails"}
                                    state={{ editpost: post }}
                                  >
                                    {" "}
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              <NavLink
                                to={"/departmentrequisitions"}
                                state={{ editpost: post?.job_opening?.department?.id }}
                              >
                                {post?.job_opening?.department
                                  ?.department_display_name || "-"}
                              </NavLink>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.priority || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.pending_since}
                            </span>
                          </div>

                          {location.state?.action !== 'Upload' &&
                            <>
                              {privileges.includes(
                                "agency_outreach_resume_upload"
                              ) ? (
                                <div className="nk-tb-col">
                                  <NavLink
                                    to={"/re3create"}
                                    className="btn btn-sm btn-primary"
                                    id="button-button-re3create"
                                    state={{
                                      editpost: post,
                                      action: "Outreach"
                                    }}
                                    onClick={() => onTATSubmit(post)}
                                  >
                                    <em className="icon ni ni-plus"></em>
                                  </NavLink>
                                </div>
                              ) : (
                                " "
                              )}
                            </>
                          }
                          {location.state?.action === 'Upload' &&
                            <>
                              {privileges.includes(
                                "agency_outreach_resume_upload"
                              ) ? (
                                <div className="nk-tb-col">
                                  <NavLink
                                    to={"/re3create"}
                                    className="btn btn-sm btn-primary"
                                    id="button-button-re3create"
                                    state={{
                                      editpost: post,
                                      action: "Upload"
                                    }}
                                    onClick={() => onTATSubmit(post)}
                                  >
                                    <em class="icon ni ni-upload"></em>
                                  </NavLink>
                                </div>
                              ) : (
                                " "
                              )}
                            </>
                          }

                          {/* {privileges.includes("error_resume_download") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/resumedownload"}
                                className="btn btn-sm btn-primary"
                                id="button-button-re3zipdownload"
                              >
                                <em className="icon ni ni-download"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )} */}
                        </div>
                      ))
                    ) : isRE3Data ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={re3PageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={re3PageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => handleRE3Click(data, searchRE3)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {re3Count === null ? 0 : re3Count}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={re3Entries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RE3;
